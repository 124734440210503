import axios from "axios";
import authHeader from "@/services/auth-header";
import { websiteURL } from "@/utils";
import { useAuthStore } from "@/stores/AuthStore";

const API_URL = websiteURL + "api/auth/";

class AuthService {
  login(user) {
    console.log(user.keepSignedIn);
    return axios
      .post(API_URL + "signin", {
        username: user.username,
        password: user.password,
        keepSignedIn: user.keepSignedIn,
      })
      .then((response) => {
        return response.data;
      });
  }

  logout() {
    sessionStorage.clear();
    localStorage.removeItem("User");
  }

  forgotPassword(data) {
    console.log("Forgot Password " + API_URL + "forgotpassword");
    console.log(data);
    return axios.post(API_URL + "forgotpassword", {
      email: data.email,
    });
  }

  resetPassword(data) {
    console.log("Reset Password " + API_URL + "resetpassword");

    return axios.post(API_URL + "resetpassword", {
      password: data.password,
      token: data.token,
    });
  }

  register(user) {
    console.log("Trying To Register " + API_URL + "signup");
    let userRoles = [];
    userRoles.push(user.roles);

    return axios.post(API_URL + "signup", {
      email: user.email,
      password: user.password,
      title: user.title,
      gender: user.gender,
      ethnicity: user.ethnicity,
      firstName: user.firstName,
      lastName: user.lastName,
      nameOrg: user.nameOrg,
      roles: userRoles,
    });
  }

  resendVerification(email) {
    return axios.post(API_URL + "resend", {
      email: email,
    });
  }

  saveProfile(profileData) {
    const AuthStore = useAuthStore();
    console.log("Trying To Save Profile " + API_URL + "user/profile");
    console.log("UserId " + AuthStore.auth.id);
    console.log(profileData);
    return axios.post(
      API_URL + "user/profile",
      {
        title: profileData.title,
        firstName: profileData.firstName,
        lastName: profileData.lastName,
        nameOrg: profileData.nameOrg,
        city: profileData.city,
        state: profileData.state,
        department: profileData.department,
        physicalDis: JSON.stringify(profileData.physicalDis),
        ageRange: profileData.ageRange,
        insType: profileData.insType,
        yearsOfExp: profileData.yearsOfExp,
        pronouns: profileData.pronouns,
        gender: profileData.gender,
        ethnicity: profileData.ethnicity,
        parkingArrangement: profileData.parkingArrangement,
        parkingAddress: profileData.parkingAddress,
        officeAddress: profileData.officeAddress,
        userId: AuthStore.auth.id,
      },
      { headers: authHeader() }
    );
  }

  saveIndProfile(profileData) {
    const AuthStore = useAuthStore();
    console.log("Trying To Save Profile " + API_URL + "user/ind/profile");
    console.log("UserId " + AuthStore.auth.id);
    return axios.post(
      API_URL + "user/ind/profile",
      {
        title: profileData.title,
        firstName: profileData.firstName,
        lastName: profileData.lastName,
        nameOrg: profileData.nameOrg,
        ageRange: profileData.ageRange,
        countryStudy: profileData.countryStudy,
        ethnicDiv: profileData.ethnicDiv,
        expertise: JSON.stringify(profileData.expertise),
        genderDiv: profileData.genderDiv,
        levelEducation: profileData.levelEducation,
        city: profileData.city,
        state: profileData.state,
        organPolicy: profileData.organPolicy,
        physicalDis: JSON.stringify(profileData.physicalDis),
        position: profileData.position,
        profLicense: JSON.stringify(profileData.profLicense),
        size: profileData.size,
        type: profileData.type,
        website: profileData.website,
        yearsOfExp: profileData.yearsOfExp,
        pronouns: profileData.pronouns,
        gender: profileData.gender,
        ethnicity: profileData.ethnicity,
        typeTechnology: JSON.stringify(profileData.typeTechnology),
        typeProject: JSON.stringify(profileData.typeProject),
        userId: AuthStore.auth.id,
      },
      { headers: authHeader() }
    );
  }

  saveProfilePicture(profilePicture) {
    console.log("Trying To Save Profile " + API_URL + "user/profile/picture");

    return axios.post(API_URL + "user/profile/picture", profilePicture, {
      headers: authHeader(),
    });
  }

  getProfile(userId) {
    return axios.get(API_URL + "user/profile/", {
      headers: authHeader(),
    });
  }

  changeEmail(data) {
    let userRoles = [];
    userRoles.push(data.roles);
    return axios.post(
      API_URL + "user/update",
      {
        email: data.email,
        roles: JSON.stringify(userRoles),
      },
      {
        headers: authHeader(),
      }
    );
  }

  deleteAccount(data) {
    console.log("Change Email " + API_URL + "user/delete");
    console.log(data);
    return axios.post(
      API_URL + "user/delete",
      {
        reasonDelete: data.reasonDelete,
      },
      {
        headers: authHeader(),
      }
    );
  }
}

export default new AuthService();
