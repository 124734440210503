import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { showErrorToast } from "@/utils";

import HomeView from "@/views/HomeView.vue";
import UserRegistration from "@/views/RegistrationView.vue";
import ResetPassword from "@/views/ResetPasswordView.vue";
import AboutView from "@/views/AboutView.vue";
import ContactUs from "@/views/ContactUsView.vue";
import ForgotPassword from "@/views/ForgotPasswordView.vue";
import UserFeedback from "@/views/FeedbackView.vue";
import ViewNotifications from "@/views/NotificationsView.vue";
import ViewCourseRequest from "@/views/CourseRequestView.vue";
import SearchResults from "@/views/SearchResultsView.vue";
import UserProfile from "@/views/ProfileView.vue";
import UserSettings from "@/views/SettingsView.vue";
import UserGuide from "@/views/UserGuideView.vue";
import Upload from "@/views/UploadView.vue";
import MyUploads from "@/views/MyUploadsView.vue";
import UserList from "@/views/UserListView.vue";
import Dashboard from "@/views/DashboardView.vue";
import DashboardFeedback from "@/views/DashboardFeedbackView.vue";
import GenVerLinkView from "@/views/GenVerLinkView.vue";
import UserAgreement from "@/views/legal/UserAgreement.vue";
import PrivacyPolicy from "@/views/legal/PrivacyPolicyView.vue";
import FAQView from "@/views/FAQView.vue";

import InstructorProfile from "@/views/instructor/UpdateProfileView.vue";
import InstructorFeed from "@/views/instructor/FeedView.vue";
import CourseRequest from "@/views/instructor/SavedCourseRequestView.vue";
import UpdateCourseRequest from "@/views/instructor/courseRequest/UpdateView.vue";
import EditCourseRequest from "@/views/instructor/courseRequest/EditSubmitView.vue";
import ViewCourseDetails from "@/views/instructor/courseRequest/DisplayView.vue";
import Resources from "@/views/instructor/resouces/CreateView.vue";
import ViewRecommendations from "@/views/instructor/RecommendationsView.vue";
import ViewSelectedRecommendations from "@/views/instructor/SelectedRecommendationsView.vue";
import InstructorResources from "@/views/instructor/ResourcesView.vue";
import MyClasses from "@/views/instructor/class/MyClassesView.vue";
import CreateClassCourseRequest from "@/views/instructor/courseRequest/CreateView.vue";
import ClassCourseRequest from "@/views/instructor/class/ClassCourseRequestView.vue";
import ViewPractitionerProfile from "@/views/instructor/PractitionerProfileView.vue";

import IndustryPractitionerProfile from "@/views/practitioner/UpdateProfileView.vue";
import IndustryPractitionerFeed from "@/views/practitioner/FeedView.vue";
import ResourcesFeed from "@/views/practitioner/ResourcesFeed.vue";
import CourseRequestView from "@/views/practitioner/CourseRequestView.vue";
import IndustryPractitionerAvail from "@/views/practitioner/AvailabilityView.vue";
import ViewInstructorProfile from "@/views/practitioner/InstructorProfileView.vue";
import PastCourseRequestView from "@/views/practitioner/PastCourseRequestView.vue";

import AdminFeed from "@/views/admin/FeedView.vue";
import SiteStatistics from "@/views/admin/SiteStatisticsView.vue";
import DatabaseTable from "@/views/admin/DatabaseTableView.vue";
import AddAdmin from "@/views/admin/AddAdminView.vue";
import CategorizeTopics from "@/views/admin/CategorizeTopics.vue";
import FieldSelection from "@/views/admin/FieldSelectionView.vue";

import { User } from "@/types";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    alias: ["/home", "/index.html"],
  },
  {
    path: "/register",
    name: "register",
    component: UserRegistration,
  },
  {
    path: "/forgotPassword",
    name: "forgot-password",
    component: ForgotPassword,
  },
  {
    path: "/resetPassword/:token",
    name: "reset-password",
    component: ResetPassword,
    props: true,
  },
  {
    path: "/resetLink",
    name: "reset-ver-link",
    component: GenVerLinkView,
    props: true,
  },
  {
    path: "/about",
    name: "about",
    component: AboutView,
  },
  {
    path: "/contact",
    name: "contact-us",
    component: ContactUs,
  },
  {
    path: "/feedback",
    name: "feedback",
    component: UserFeedback,
  },
  {
    path: "/help/faq",
    name: "faq",
    component: FAQView,
  },
  {
    path: "/legal/user-agreement",
    name: "user-agreement",
    component: UserAgreement,
  },
  {
    path: "/legal/privacy-policy",
    name: "privacy-policy",
    component: PrivacyPolicy,
  },
  {
    path: "/search/:keyword",
    name: "search",
    component: SearchResults,
    props: true,
  },
  {
    path: "/profile/:userId",
    name: "user-profile",
    component: UserProfile,
    props: true,
  },
  {
    path: "/search/:userId",
    name: "search-profile",
    component: UserProfile,
    props: true,
  },
  {
    path: "/users/:role",
    name: "users",
    component: UserList,
    props: true,
  },
  {
    path: "/settings/",
    name: "settings",
    component: UserSettings,
  },
  {
    path: "/guide/",
    name: "guide",
    component: UserGuide,
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
  },
  {
    path: "/dashboard/feedback",
    name: "dashboard-feedback",
    component: DashboardFeedback,
  },
  {
    path: "/upload",
    name: "upload",
    component: Upload,
  },
  {
    path: "/uploads",
    name: "view-uploads",
    component: MyUploads,
  },
  {
    path: "/course/:id",
    name: "view-course-request",
    component: ViewCourseRequest,
  },
  {
    path: "/instructor/profile",
    name: "instructor-profile",
    component: InstructorProfile,
  },
  {
    path: "/practitioner/profile",
    name: "industry-practitioner-profile",
    component: IndustryPractitionerProfile,
  },
  {
    path: "/practitioner/availability",
    name: "industry-practitioner-availability",
    component: IndustryPractitionerAvail,
  },
  {
    path: "/instructor/course",
    name: "course-request",
    component: CourseRequest,
  },
  {
    path: "/instructor/course/view",
    name: "view-course-request-ins",
    component: ViewCourseDetails,
  },
  {
    path: "/instructor/resources/new",
    name: "new-resources",
    component: Resources,
  },

  {
    path: "/instructor/resources/view",
    name: "instructor-view-resources",
    component: InstructorResources,
  },
  {
    path: "/practitioner/course",
    name: "view-course-request-prac",
    component: CourseRequestView,
  },
  {
    path: "/practitioner/course/past",
    name: "view-past-course-request",
    component: PastCourseRequestView,
  },
  {
    path: "/practitioner/notifications",
    name: "view-notifications",
    component: ViewNotifications,
  },
  {
    path: "/instructor/notifications",
    name: "view-notifications-ins",
    component: ViewNotifications,
  },
  {
    path: "/instructor/course/recommendations/profile",
    name: "view-industry-practitioner-ins",
    component: ViewPractitionerProfile,
  },
  {
    path: "/practitioner/instructor/profile",
    name: "view-instructor-profile",
    component: ViewInstructorProfile,
  },
  {
    path: "/instructor/course/recommendations",
    name: "view-recommendations",
    component: ViewRecommendations,
  },
  {
    path: "/instructor/course/recommendations/selected",
    name: "view-recommendations-selected",
    component: ViewSelectedRecommendations,
  },
  {
    path: "/instructor/course/update",
    name: "update-course-request",
    component: UpdateCourseRequest,
  },
  {
    path: "/instructor/course/edit",
    name: "edit-course-request",
    component: EditCourseRequest,
  },
  {
    path: "/instructor/home",
    name: "instructor-feed",
    component: InstructorFeed,
  },
  {
    path: "/practitioner/home",
    name: "industry-practitioner-feed",
    component: IndustryPractitionerFeed,
  },
  {
    path: "/practitioner/resources/view",
    name: "view-resources",
    component: ResourcesFeed,
  },
  {
    path: "/instructor/classes",
    name: "instructor-classes",
    component: MyClasses,
  },
  {
    path: "/instructor/class/course/:courseCode",
    name: "class-course-request",
    component: ClassCourseRequest,
    props: true,
  },
  {
    path: "/instructor/class/course/create",
    name: "create-class-course-request",
    component: CreateClassCourseRequest,
  },
  {
    path: "/admin/home",
    name: "admin-feed",
    component: AdminFeed,
  },
  {
    path: "/admin/statistics",
    name: "site-statistics",
    component: SiteStatistics,
  },
  {
    path: "/admin/database/:name",
    name: "database-table",
    component: DatabaseTable,
    props: true,
  },
  {
    path: "/admin/categorize/:name",
    name: "categorize-topics",
    component: CategorizeTopics,
  },
  {
    path: "/admin/fields",
    name: "field-selection",
    component: FieldSelection,
  },
  {
    path: "/reset/admin/:token",
    name: "admin-details",
    component: AddAdmin,
    props: true,
  },
  {
    path: "/admin/notifications",
    name: "view-notifications-admin",
    component: ViewNotifications,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    if (to.hash) {
      return {
        el: to.hash,
        top: 64,
      };
    }
    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const publicPages = [
    "/register",
    "/",
    "/index.html",
    "/home",
    "/forgotPassword",
    "/resetPassword/:token",
    "/resetLink",
    "/about",
    "/contact",
    "/reset/admin/:token",
    "/legal/user-agreement",
    "/legal/privacy-policy",
    "/help/faq",
  ];

  const profilePages = [
    "/instructor/profile",
    "/practitioner/profile",
    "/admin/home",
  ];

  // availability
  const availabilityPages = ["/practitioner/availability"];

  const loginPages = [
    "/",
    "/index.html",
    "/home",
    "/register",
    "/forgotPassword",
    "/resetPassword/:token",
    "/resetLink",
    "/reset/admin/:token",
  ];

  const resetPasswordPages = [
    "/resetPassword/:token",
    "/resetLink",
    "/reset/admin/:token",
  ];

  const instructorPageRequest = to.path.startsWith("/instructor");
  const practitionerPageRequest = to.path.startsWith("/practitioner");
  // const adminPageRequest = to.path.startsWith("/admin");
  const adminPageRequest = false;
  const authRequired = !(
    publicPages.includes(to.path) || to.path.startsWith("/reset")
  );
  const loggedInPages = loginPages.includes(to.path);

  const profilePage = profilePages.includes(to.path);
  // availability
  const availabilityPage = availabilityPages.includes(to.path);

  const userString = localStorage.getItem("User");
  const loggedIn = {} as User;

  if (userString !== null) {
    const userFromStorage = JSON.parse(userString);
    Object.assign(loggedIn, userFromStorage);
  }

  // console.log(to.path);
  // console.log(authRequired);
  // console.log(Object.keys(loggedIn || {}).length > 0);

  if (authRequired && Object.keys(loggedIn || {}).length == 0) {
    next({ name: "home", query: { redirect: to.path } });
  } else if (
    Object.keys(loggedIn || {}).length > 0 &&
    to.path.startsWith("/notification")
  ) {
    if (loggedIn.roles === "INDUSTRYPRACTITIONER") {
      next({
        name: "industry-practitioner-feed",
        query: { redirect: to.path },
      });
    } else if (loggedIn.roles === "INSTRUCTOR") {
      next({
        name: "instructor-feed",
        query: { redirect: to.path },
      });
    }
  } else if (loggedInPages && Object.keys(loggedIn || {}).length > 0) {
    if (loggedIn.roles === "INSTRUCTOR") next("/instructor/home");
    else if (loggedIn.roles === "INDUSTRYPRACTITIONER")
      next("/practitioner/home");
    else if (loggedIn.roles === "ADMIN") next("/admin/home");
  } else if (
    authRequired &&
    !profilePage &&
    Object.keys(loggedIn || {}).length > 0 &&
    loggedIn.profile == null
  ) {
    if (loggedIn.roles === "INSTRUCTOR") {
      showErrorToast(
        "You need to complete your profile before you can access other parts of the web platform"
      );
      next("/instructor/profile");
    } else if (loggedIn.roles === "INDUSTRYPRACTITIONER") {
      showErrorToast(
        "You need to complete your profile before you can access other parts of the web platform"
      );
      next("/practitioner/profile");
    } else if (loggedIn.roles === "ADMIN") next();
  } else if (
    authRequired &&
    !profilePage &&
    !availabilityPage &&
    Object.keys(loggedIn || {}).length > 0 &&
    loggedIn.roles === "INDUSTRYPRACTITIONER" &&
    loggedIn.profile != null &&
    loggedIn.profile?.courseRequestType == null
  ) {
    // availability
    showErrorToast(
      "You need to specify your availability before you can access other parts of the web platform"
    );
    next("/practitioner/availability");
    // next();
  } else if (
    (instructorPageRequest && loggedIn.roles !== "INSTRUCTOR") ||
    (practitionerPageRequest && loggedIn.roles !== "INDUSTRYPRACTITIONER") ||
    (adminPageRequest && loggedIn.roles !== "ADMIN")
  ) {
    next("/home");
  } else {
    next();
  }
});

export default router;
