import axios from "axios";
import authHeader from "./auth-header";
import { websiteURL } from "@/utils";

const API_URL = websiteURL + "api/admin/";

class AdminService {
  getUsers() {
    // console.log(API_URL + "users");
    return axios.get(API_URL + "users", { headers: authHeader() });
  }

  updatePractitionerRecommendationAvail(userId, recommendationDisable) {
    // console.log(API_URL + "users/" + userId);
    return axios.patch(
      API_URL + "users/" + userId + "/recommendation",
      { recommendationDisable: recommendationDisable },
      {
        headers: authHeader(),
      }
    );
  }

  changeUserStatus(userId, data) {
    // console.log(API_URL + "users/" + userId);
    return axios.patch(API_URL + "users/" + userId, data, {
      headers: authHeader(),
    });
  }

  getInstructors() {
    // console.log(API_URL + "instructors");
    return axios.get(API_URL + "instructors", { headers: authHeader() });
  }

  getClasses() {
    // console.log(API_URL + "class");
    return axios.get(API_URL + "class", { headers: authHeader() });
  }

  getCourseRequests() {
    // console.log(API_URL + "courses");
    return axios.get(API_URL + "courses", { headers: authHeader() });
  }

  assignPractitioner(courseId, data) {
    // console.log(API_URL + "users/" + userId);
    return axios.patch(API_URL + "courses/" + courseId + "/assign", data, {
      headers: authHeader(),
    });
  }

  removePractitioner(courseId) {
    // console.log(API_URL + "users/" + userId);
    return axios.patch(
      API_URL + "courses/" + courseId + "/remove",
      {},
      {
        headers: authHeader(),
      }
    );
  }

  assignRecommendation(courseId, data) {
    // console.log(API_URL + "courses/" + userId);
    return axios.patch(API_URL + "courses/" + courseId + "/recommend", data, {
      headers: authHeader(),
    });
  }

  getResourceRequests() {
    // console.log(API_URL + "resources");
    return axios.get(API_URL + "resources", { headers: authHeader() });
  }

  getIndustryPractitioners() {
    // console.log(API_URL + "practitioners");
    return axios.get(API_URL + "practitioners", { headers: authHeader() });
  }

  getAvailability() {
    // console.log(API_URL + "availability");
    return axios.get(API_URL + "availability", { headers: authHeader() });
  }

  getUploads() {
    // console.log(API_URL + "uploads");
    return axios.get(API_URL + "uploads", { headers: authHeader() });
  }

  deleteProjectDetail(contentId) {
    // console.log(API_URL + "uploads/" + contentId);
    return axios.patch(
      API_URL + "uploads/" + contentId,
      {},
      { headers: authHeader() }
    );
  }

  getFeedback() {
    // console.log(API_URL + "feedback");
    return axios.get(API_URL + "feedback", { headers: authHeader() });
  }

  getContactUs() {
    // console.log(API_URL + "contact");
    return axios.get(API_URL + "contact", { headers: authHeader() });
  }

  getOrganization() {
    console.log(API_URL + "organization");
    return axios.get(API_URL + "organization", { headers: authHeader() });
  }

  getDashboardAccessRequests() {
    console.log(API_URL + "dashboard");
    return axios.get(API_URL + "dashboard", { headers: authHeader() });
  }

  getDashboardFeedback() {
    // console.log(API_URL + "feedback");
    return axios.get(API_URL + "dashboard/feedback", { headers: authHeader() });
  }

  addDashboardAccessRequest(data) {
    // console.log(API_URL + "dashboard/" + requestId);
    return axios.post(API_URL + "dashboard", data, {
      headers: authHeader(),
    });
  }

  updateDashboardAccessRequest(requestId, data) {
    // console.log(API_URL + "dashboard/" + requestId);
    return axios.patch(API_URL + "dashboard/" + requestId, data, {
      headers: authHeader(),
    });
  }

  saveOrganization(data) {
    // console.log("Save Organization " + API_URL + "organization");
    return axios.patch(API_URL + "organization/" + data.id, data, {
      headers: authHeader(),
    });
  }

  addAdmin(data) {
    // console.log("Reset Password " + API_URL + "add");

    return axios.post(
      API_URL + "add",
      {
        email: data.email,
      },
      { headers: authHeader() }
    );
  }

  setAdminDetails(data) {
    // console.log("Set Admin Password " + API_URL + "set/admin");

    return axios.post(API_URL + "set/admin", {
      password: data.password,
      gender: data.gender,
      ethnicity: data.ethnicity,
      firstName: data.firstName,
      lastName: data.lastName,
      token: data.token,
    });
  }

  updateFeedback(data) {
    // console.log("Update Feedback " + API_URL + "feedback");

    return axios.post(
      API_URL + "feedback",
      {
        data: data,
      },
      { headers: authHeader() }
    );
  }

  getNotifications() {
    // console.log(API_URL + "notification");
    return axios.get(API_URL + "notification", { headers: authHeader() });
  }

  getFAQs() {
    // console.log(API_URL + "faq");
    return axios.get(API_URL + "faq", { headers: authHeader() });
  }
  saveFAQ(data) {
    // console.log("Save FAQ " + API_URL + "faq");
    return axios.patch(API_URL + "faq/" + data.id, data, {
      headers: authHeader(),
    });
  }

  getCategories(categoryTable) {
    // console.log(API_URL + "categories");
    return axios.get(API_URL + "category/" + categoryTable, {
      headers: authHeader(),
    });
  }

  createCategory(data, categoryTable) {
    console.log("Create Category " + API_URL + "category/" + categoryTable);
    return axios.post(
      API_URL + "category/" + categoryTable,
      {
        name: data.name,
        table: categoryTable,
        status: "01",
      },
      { headers: authHeader() }
    );
  }

  updateCategory(data, categoryId) {
    // console.log(API_URL + "topic");
    return axios.patch(API_URL + "category/" + categoryId, data, {
      headers: authHeader(),
    });
  }

  deleteCategory(categoryId) {
    // console.log(API_URL + "topic");
    return axios.delete(API_URL + "category/" + categoryId, {
      headers: authHeader(),
    });
  }

  getTopic(categoryTable) {
    // console.log(API_URL + "topic");
    return axios.get(API_URL + "topic/" + categoryTable, {
      headers: authHeader(),
    });
  }

  updateTopic(topicId, categoryId) {
    // console.log(API_URL + "topic");
    return axios.patch(
      API_URL + "topic/" + topicId,
      { categoryId: categoryId },
      {
        headers: authHeader(),
      }
    );
  }
}

export default new AdminService();
