import { defineStore } from "pinia";
import { Config } from "@/types";
import UserService from "@/services/user.service.js";

const CONFIG_STORAGE_KEY = "Config";

export const useConfigStore = defineStore("ConfigStore", {
  state: () => ({
    config: [] as Config[],
  }),
  getters: {
    status(): boolean {
      return this.config.length > 0;
    },
  },
  actions: {
    getConfig() {
      UserService.getConfig().then(
        (response) => {
          // console.log(response.data);
          this.config = response.data;
        },
        (error) => {
          const res =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          console.log(res);
        }
      );
    },
  },
  persist: {
    storage: sessionStorage,
    key: CONFIG_STORAGE_KEY,
  },
});
