<template>
  <div class="m-5 ml-0 min-height header-space">
    <div class="text-align-center">
      <img
        src="@/assets/images/site/privacy-policy.jpg"
        class="site-image mx-auto"
        autofocus
      />
    </div>
    <div class="mt-5 flex flex-row">
      <div class="logo-container flex-grow-1 flex-shrink-0">
        <img id="profile-img" class="m-auto" src="@/assets/images/logo.png" />
        <p style="font-size: 1.5rem" class="text-align-center">
          ...connecting professional and educational communities for future
          workforce development.
        </p>
      </div>
      <div>
        <h1>Privacy Policy</h1>
        <div>
          <div>
            <div class="heading">Introduction</div>
            ConPEC is a collaborative networking platform for professional and
            educational communities to collaborate for future workforce
            development. The platform is targeted at instructors and
            practitioners in the construction engineering domain. The platform
            allows practitioners and instructors to connect. The purpose of this
            connection is for collaborations that would ensure students adequate
            preparation for the workplace through practitioners’ complementary
            inputs in instructors’ pedagogical efforts.
          </div>
          <div>
            <div class="heading">Services</div>
            This Privacy Policy applies to ConPEC. As a Visitor or Member of
            ConPEC, the collection, use, and sharing of your data is subject to
            this Privacy Policy.
          </div>
          <div>
            <div class="heading">Change</div>
            Changes to the Privacy Policy apply to your use of ConPEC after the
            “effective date.” ConPEC (“we” or “us” or “the Platform”) can modify
            this Privacy Policy, and if we make material changes to it, we will
            provide notice through the Platform, or by other means, to provide
            you the opportunity to review the changes before they become
            effective. If you object to any changes, you may close your account.
            You acknowledge that your continued use of ConPEC after we publish
            or send a notice about our changes to this Privacy Policy means that
            the collection, use, and sharing of your data is subject to the
            updated Privacy Policy, as of its effective date.
          </div>
          <ol class="heading-list">
            <li>
              <div class="heading">1. Data We Collect</div>
              <ol class="ml-6">
                <li>
                  <div class="sub-heading ml-0">1.1 Data You Provide to Us</div>
                  You provide data to create an account with us.
                  <div class="sub-sub-heading">Registration</div>
                  To create an account, you need to provide data including your
                  name, email address and/or mobile number, and a password.
                  <div class="sub-sub-heading">Profile</div>
                  You have choices about the information on your profile,
                  however, you need to complete your profile truthfully before
                  you can use the platform maximally. The Platform shows both
                  your public and private profile so that you can easily know
                  the information that others see about you.
                  <div class="sub-sub-heading">Posting and Uploading</div>
                  Content you upload to the Platform can be accessed by other
                  users. This is also subject to the visibility reach you
                  indicate.
                </li>
                <li>
                  <div class="sub-heading ml-0">1.2 Service Use</div>
                  We log your visits and use of ConPEC. We log usage data when
                  you visit or otherwise use ConPEC such as when you view or
                  click on content, perform a search, or other actions. We use
                  User login details to identify users and log their use of the
                  Platform.
                </li>
                <li>
                  <div class="sub-heading ml-0">
                    1.3 Cookies and Similar Technologies
                  </div>
                  We collect data through cookies and similar technologies.
                </li>
                <li>
                  <div class="sub-heading ml-0">
                    1.4 Your Device and Location
                  </div>
                  We receive data through cookies and similar technologies
                </li>
                <li>
                  <div class="sub-heading ml-0">1.5 Other</div>
                  We are improving our Services, which means we get new data and
                  create new ways to use data.<br />
                  ConPEC is dynamic, and we often introduce new features, which
                  may require the collection of new information. If we collect
                  materially different personal data or materially change how we
                  collect, use, or share your data, we will notify you and may
                  also modify this Privacy Policy.
                </li>
              </ol>
            </li>
            <li>
              <div class="heading">2. How We Use Your Data</div>
              <div class="ml-4">
                We use your data to provide, support, personalize, and develop
                ConPEC. The de-identified data could also be used for research
                purposes
                <ol>
                  <li>
                    <div class="sub-heading">2.1 Services</div>
                    <div class="ml-4">
                      Our Services help you connect with others, provide or
                      offer support and request for course-support for student
                      development. Instructors can connect with willing and able
                      practitioners who can support their pedagogical efforts.
                      Similarly, practitioners can connect with instructors by
                      responding to the requests from instructors. The data you
                      provide on the platform is primarily used for this
                      purpose. Instructors also have opportunities to request
                      resources (video, documents and pictures/images) from
                      practitioners, while practitioners have the liberty to
                      share resources that instructors can use to support or as
                      instructional materials for academic purposes only. No
                      user is being charged for the usage of ConPEC.
                    </div>
                  </li>
                  <li>
                    <div class="sub-heading">2.2 Communications</div>
                    <div class="ml-4">
                      We contact you and enable communications between Members.
                      We will contact you through email, mobile phone, and
                      notices posted on the Platform. Please be aware that you
                      cannot opt out of receiving service messages from us. We
                      also enable communications between you and others through
                      the Platform. Notices from the Platform can also be sent
                      to your contact information.
                    </div>
                  </li>
                  <li>
                    <div class="sub-heading">2.3 Advertising</div>
                    <div class="ml-4">
                      No advert is being displayed on the Platform.
                    </div>
                  </li>
                  <li>
                    <div class="sub-heading">
                      2.4 Developing Services and Research
                    </div>
                    <div class="ml-4">
                      <div class="sub-sub-heading">Service Development</div>
                      We use data, including feedback, to conduct research and
                      development for ConPEC to provide you and others with a
                      better, more intuitive, and personalized experience, drive
                      membership growth and engagement on the Platform, and help
                      connect instructors and practitioners for student
                      development.
                      <div class="sub-sub-heading">Other Research</div>
                      We also use the de-identified data you provide as well as
                      the usage and pattern of interaction on the platform for
                      academic research to extend the frontiers of knowledge.
                      <div class="sub-sub-heading">Surveys</div>
                      Polls and surveys may be conducted by us and you are not
                      obligated to respond.
                    </div>
                  </li>
                  <li>
                    <div class="sub-heading">2.5 Contact Us/Feedback</div>
                    <div class="ml-4">
                      We use data to help you and fix problems. We use data
                      (which can include your communications) to investigate,
                      respond to, and resolve complaints (e.g., bugs).
                    </div>
                  </li>
                  <li>
                    <div class="sub-heading">
                      2.6 Insights That Do Not Identify You
                    </div>
                    <div class="ml-4">
                      We use data to generate insights that do not identify you.
                      We use your data to produce and share insights that do not
                      identify you. For example, we may use your data to
                      generate statistics about our members, their profession,
                      or industry, or to publish demographics or create
                      demographic workforce insights.
                    </div>
                  </li>
                  <li>
                    <div class="sub-heading">
                      2.7 Security and Investigations
                    </div>
                    <div class="ml-4">
                      We use data for security, fraud prevention, and
                      investigations. We use your data (including your
                      communications) for security purposes or to prevent or
                      investigate possible fraud or other violations of our User
                      Agreement and/or attempts to harm our Members or others.
                    </div>
                  </li>
                </ol>
              </div>
            </li>
            <li>
              <div class="heading">3. How We Share Information</div>
              <ol class="ml-6">
                <li>
                  <div class="sub-heading ml-0">3.1 Our Services</div>
                  Any data that you include on your profile and any content you
                  post will be seen by others, consistent with your settings.
                  <div class="sub-sub-heading">Profile</div>
                  Your public profile is fully visible to other Members.
                  <div class="sub-sub-heading">Posts/Upload Resources</div>
                  When you upload a resource (picture/image, video, and
                  document) publicly it can be viewed by everyone and re-shared
                  anywhere (subject to your settings). Other users will not be
                  able to identify content uploaded by you. That is the content
                  you upload is not linked to you for other users to see.
                  However, if you share content directly with a user, only that
                  user will be able to access it. However, the user might share
                  the content with others and this is beyond our control.
                </li>
                <li>
                  <div class="sub-heading ml-0">3.2 Legal Disclosures</div>
                  We may need to share your data when we believe it’s required
                  by law or to help protect the rights and safety of you, us, or
                  others. We may need to disclose information about you when
                  required by law, subpoena, or other legal process or if we
                  have a good faith belief that disclosure is reasonably
                  necessary to (1) investigate, prevent, or take action
                  regarding suspected or actual illegal activities or to assist
                  government enforcement agencies; (2) enforce our agreements
                  with you; (3) investigate and defend ourselves against any
                  third-party claims or allegations; (4) protect the security or
                  integrity of ConPEC; or (5) exercise or protect the rights and
                  safety of ConPEC, our Members, personnel or others. We attempt
                  to notify Members about legal demands for their data when
                  appropriate in our judgment, unless prohibited by law or court
                  order or when the request is an emergency. We may dispute such
                  demands when we believe, in our discretion, that the requests
                  are overbroad, vague, or lack proper authority, but we do not
                  promise to challenge every demand.
                </li>
              </ol>
            </li>
            <li>
              <div class="heading">4. Your Choices & Obligations</div>
              <ol class="ml-6">
                <li>
                  <div class="sub-heading ml-0">4.1 Data Retention</div>
                  We keep most of your data for as long as your account is open.
                  We generally retain your data as long as you keep your account
                  open or as needed to provide you with usage of the Platform.
                  This includes data you or others provided to us and data
                  generated or inferred from your use of the Platform.
                </li>
                <li>
                  <div class="sub-heading ml-0">4.2 Account Closure</div>
                  We keep some of your data even after you close your account.
                  If you choose to delete your account, your data will generally
                  stop being visible to others on the Platform within 24 hours.
                  We will retain de-personalized information after your account
                  has been closed. Information you have shared with others
                  (e.g., through uploads) will remain visible after you close
                  your account or delete the information from your profile or
                  mailbox, and we do not control data that other Members have
                  copied out of the Platform.
                </li>
              </ol>
            </li>
            <li>
              <div class="heading">5. Other Important Information</div>
              <ol class="ml-6">
                <li>
                  <div class="sub-heading ml-0">5.1. Security</div>
                  We implement security safeguards designed to protect your
                  data, such as HTTPS. We regularly monitor our systems for
                  possible vulnerabilities and attacks. However, we cannot
                  warrant the security of any information that you send us.
                  There is no guarantee that data may not be accessed,
                  disclosed, altered, or destroyed by breach of any of our
                  physical, technical, or managerial safeguards.
                </li>
                <li>
                  <div class="sub-heading ml-0">
                    5.2. Direct Marketing and Do Not Track Signals
                  </div>
                  We currently do not share personal data with third parties for
                  their direct marketing purposes without your permission.
                </li>
                <li>
                  <div class="sub-heading ml-0">5.3. Contact Information</div>
                  You can
                  <router-link :to="{ name: 'contact-us' }">
                    contact us</router-link
                  >
                  to resolve any complaints.
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
.heading {
  font-size: 1.5rem;
  line-height: 1.25;
  margin-top: 2rem;
  font-weight: bold;
}

.sub-heading {
  font-size: 1.25rem;
  margin-top: 1rem;
  margin-left: 1.5rem;
  font-weight: bold;
}

.sub-sub-heading {
  font-weight: bold;
}

.heading-list > li {
  border-bottom: 1px solid #cfcfcf;
  padding-bottom: 1rem;
}
</style>
