import { defineStore } from "pinia";
import { Notification } from "@/types";
import UserService from "@/services/user.service.js";
import AdminService from "@/services/admin.service.js";

const NOTIFICATION_STORAGE_KEY = "Notifications";

export const useNotificationStore = defineStore("NotificationsStore", {
  state: () => ({
    notifications: [] as Notification[],
  }),
  getters: {
    status(): boolean {
      return this.notifications.length > 0;
    },
    count(): number {
      return this.notifications.filter(
        (notification) => notification.status === "01"
      ).length;
    },
  },
  actions: {
    getNotification() {
      UserService.getNotifications().then(
        (response) => {
          console.log(response);
          this.notifications = response.data;
        },
        (error) => {
          const res =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          console.log(res);
        }
      );
    },
    getAdminNotification() {
      AdminService.getNotifications().then(
        (response) => {
          this.notifications = response.data;
        },
        (error) => {
          const res =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          console.log(res);
        }
      );
    },

    markAsRead(notificationId: number) {
      UserService.markNotificationAsRead(notificationId);
      this.getNotification();
    },
  },
  persist: {
    storage: sessionStorage,
    key: NOTIFICATION_STORAGE_KEY,
  },
});
