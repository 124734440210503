import { defineStore } from "pinia";
import {
  Availability,
  AvailabilityObjects,
  AvailabilityTime,
  Site,
  SiteListObjects,
} from "@/types";
import PractitionerService from "@/services/practitioner.service";
import { responseParser } from "@/utils";

export const useAvailabilityStore = defineStore("AvailabilityStore", {
  state: () => ({
    availability: {} as Availability,
  }),
  getters: {
    status(): boolean {
      return Object.keys(this.availability.courseRequestType || {}).length > 0;
    },
  },
  actions: {
    async getAvailability() {
      PractitionerService.getAvailability().then(
        (response) => {
          responseParser(
            this.availability,
            response.data.availability,
            AvailabilityObjects
          );
          this.availability.siteList = [] as Site[];
          response.data.siteList.forEach((site) => {
            const emptySite = {} as Site;
            responseParser(emptySite, site, SiteListObjects);
            this.availability.siteList.push(emptySite);
          });
          this.availability.availabilityTime =
            response.data.availabilityTime || [];
        },
        (error) => {
          const res =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          console.log(res);
        }
      );
    },
    updateAvailability(availability, step, callback) {
      if (step === 0) {
        this.availability.courseRequestType = availability.courseRequestType;
        this.availability.subjectSpeak = availability.subjectSpeak;
        callback();
      } else if (step === 1) {
        this.availability.insDeliveryType = availability.insDeliveryType;
        this.availability.others = availability.others;
        this.availability.insState = availability.insState;
        this.availability.insName = availability.insName;
        this.availability.academicLevel = availability.academicLevel;
        this.availability.studentDept = availability.studentDept;
        callback();
      }
    },
  },
  persist: {
    storage: sessionStorage,
  },
});
