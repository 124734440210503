import axios from "axios";
import authHeader from "./auth-header";
import { websiteURL } from "@/utils";
import { useAuthStore } from "@/stores/AuthStore";

const API_URL = websiteURL + "api/";

class PractitionerService {
  getPractitionerBoard(page, type) {
    // console.log(API_URL + "user/course");
    return axios.get(API_URL + "user/course", {
      params: { page: page, size: 10, type: type },
      headers: authHeader(),
    });
  }

  getPendingRequests() {
    // console.log(API_URL + "practitioner/course/pending");
    return axios.get(API_URL + "practitioner/course/pending", {
      headers: authHeader(),
    });
  }

  getUserBadge() {
    // console.log(API_URL + "practitioner/course/worked");
    return axios.get(API_URL + "practitioner/course/worked", {
      headers: authHeader(),
    });
  }

  getPendingResourceRequests() {
    // console.log(API_URL + "practitioner/resource/pending");
    return axios.get(API_URL + "practitioner/resource/pending", {
      headers: authHeader(),
    });
  }

  saveAvailability(data) {
    // console.log("Trying To Save Availability " + API_URL + "user/availability");
    // console.log("UserId " + AuthStore.auth.id);

    data.siteList.forEach((el) => {
      el.physicalDis = JSON.stringify(el.physicalDis);
      el.projectStage = JSON.stringify(el.projectStage);
      el.safetyRequirement = JSON.stringify(el.safetyRequirement);
    });

    // console.log(data);

    return axios.post(
      API_URL + "user/availability",
      {
        courseRequestType: JSON.stringify(data.courseRequestType),
        subjectSpeak: JSON.stringify(data.subjectSpeak),
        subjectSpeakOthers: JSON.stringify(data.subjectSpeakOthers),
        insDeliveryType: data.insDeliveryType,
        others: data.others,
        insState: JSON.stringify(data.insState),
        insName: JSON.stringify(data.insName),
        academicLevel: JSON.stringify(data.academicLevel),
        studentDept: JSON.stringify(data.studentDept),
        siteList: JSON.stringify(data.siteList),
        availabilityTime: JSON.stringify(data.availabilityTime),
        phone: data.phone,
        phoneNumber: data.phoneNumber,
      },
      { headers: authHeader() }
    );
  }

  getAvailability() {
    // console.log(API_URL + "user/availability");
    return axios.get(API_URL + "user/availability", { headers: authHeader() });
  }

  uploadData(data) {
    // console.log("Trying To upload " + API_URL + "user/upload");
    return axios.post(API_URL + "user/upload", data, {
      headers: authHeader(),
    });
  }

  uploadResourceRequestResponse(data) {
    // console.log("Trying To upload " + API_URL + "user/upload");
    console.log(data);
    return axios.post(API_URL + "user/resource/response", data, {
      headers: authHeader(),
    });
  }

  getInstructorProfile(instructorUserId) {
    // console.log(
    //   "Trying To View Profile for Course " +
    //     API_URL +
    //     "user/profile/" +
    //     instructorUserId
    // );
    return axios.get(API_URL + "user/profile/" + instructorUserId, {
      headers: authHeader(),
    });
  }

  respondCourseReq(course, status, message = "") {
    const AuthStore = useAuthStore();
    // console.log(API_URL + "user/practitioner/course/response");
    // console.log(course);
    return axios.post(
      API_URL + "user/practitioner/course/response",
      {
        id: course.id,
        selectedDateIndex: course.selectedDateIndex,
        selectedDate: course.selectedDate,
        status: status,
        message: course.reason,
        industryPractitionerUserId: AuthStore.auth.id,
      },
      {
        headers: authHeader(),
      }
    );
  }

  selectCourse(course) {
    const AuthStore = useAuthStore();
    // console.log(API_URL + "user/practitioner/course");
    return axios.post(
      API_URL + "user/practitioner/course",
      {
        id: course.id,
        message: course.reason,
        selectedDateIndex: course.selectedDateIndex,
        selectedDate: course.selectedDate,
        industryPractitionerUserId: AuthStore.auth.id,
      },
      {
        headers: authHeader(),
      }
    );
  }
}

export default new PractitionerService();
