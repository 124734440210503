import { array } from "yup";

export interface Config {
  id: number;
  name: string;
  table: string;
  label: string;
  enabled: boolean;
  disabled: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface User {
  id: number;
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  ethnicity: string;
  gender: string;
  nameOrg: string;
  title: string;
  phone: string;
  phoneNumber: string;
  roles: string;
  accessToken: string;
  fileName: string;
  profile: Profile;
  disabled: boolean;
  opened: boolean;
  dashboardAccess: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface Profile {
  ageRange: string;
  countryStudy: string;
  ethnicDiv: string;
  expertise: string;
  genderDiv: string;
  levelEducation: string;
  city: string;
  state: string;
  organPolicy: string;
  physicalDis: string;
  position: string;
  size: string;
  type: string;
  website: string;
  yearsOfExp: string;
  department: string;
  insType: string;
  courseRequestType: string;
  profLicense: string;
  typeProject: string;
  pronouns: string;
  typeTechnology: string;
  parkingArrangement: string;
  parkingAddress: string;
  officeAddress: string;
  createdAt: string;
  updatedAt: string;
}

export interface AvailabilityTime {
  id: number;
  availabilityTime: string;
  userId: number;
}

export interface Site {
  id: number;
  availSeniorPersonnel: string;
  city: string;
  state: string;
  limitAccess: string;
  noOfStudent: string;
  physicalDis: string[];
  physicalDis_text: string;
  projectStage: string[];
  projectStage_text: string;
  projectDescr: string;
  safetyRequirement: string[];
  safetyRequirement_text: string;
  projectOverviewDuration: string;
  projectOverviewCost: string;
  projectOverviewClient: string;
  address: string;
  physicalDisReason: string;
  durationSiteVisit: string;
  projectCompletionDate: string;
  safetyReqAvailability: string;
  safetyReqAvailability_text: string;
  siteSpecificRequirements: string;
}

export const SiteListObjects = [
  "physicalDis",
  "projectStage",
  "safetyRequirement",
];

export interface Availability {
  id: number;
  courseRequestType: string[];
  subjectSpeak: string[];
  subjectSpeakOthers: string[];
  insDeliveryType: string;
  insState: string[];
  insName: string[];
  others: string;
  academicLevel: string[];
  studentDept: string[];
  siteList: Site[];
  availabilityTime: AvailabilityTime[];
  createdAt: string;
  updatedAt: string;
}

export const AvailabilityObjects = [
  "courseRequestType",
  "subjectSpeak",
  "subjectSpeakOthers",
  "insState",
  "insName",
  "academicLevel",
  "studentDept",
  "availabilityTime",
];

export interface CourseRequestPractitioner {
  selectedDate: string;
  status: string;
}

export interface CourseRequest {
  id: number;
  academicLevel: string;
  ageRange: string;
  areaOfExpertise: string;
  classSize: string;
  courseRequestType: string;
  date: string;
  duration: string;
  ethnicity: string;
  gender: string;
  learning: string;
  levelEducation: string;
  needForPreVisit: string;
  noOfFreq: string;
  physicalDis: string;
  siteOffice: string;
  studentDept: string;
  time: string;
  topic: string;
  visualRep: string;
  yearsOfExp: string;
  status: string;
  insDeliveryType: string;
  industryPractitionerUserId: number;
  userId: number;
  user: User;
  generalInfo: string;
  city: string;
  state: string;
  courseCode: string;
  courseTitle: string;
  priorKnowledge: string;
  typeProject: string;
  location: string;
  projectDescr: string;
  daysOfMeeting: string;
  endDate: string;
  classroomItems: string;
  assigmentConnected: string;
  additionalInformation: string;
  typeFirm: string;
  sizeFirm: string;
  feedbackExists: boolean;
  fileName: string;
  createdAt: string;
  updatedAt: string;
  semester: string;
  year: string;
  course: Course;
  course_request_x_practitioners: CourseRequestPractitioner[];
}

export interface Notification {
  id: number;
  status: string;
  action: string;
  linkedResourceId: number;
  linkedResourceType: string;
  user: User;
  resource_request: ResourceRequest;
  course_request: CourseRequest;
  upload: Upload;
  createdAt: string;
  updatedAt: string;
}

export interface Folder {
  id: number;
  folderId: number;
  fileName: string;
  status: string;
  files: [];
  updatedAt: string;
}

export interface Upload {
  id: number;
  type: string;
  description: string;
  visibility: string;
  fileName: string;
  filePath: string;
  origFilePath: string;
  mimetype: string;
  size: string;
  status: string;
  reasonDelete: string;
  createdAt: string;
  updatedAt: string;
  folderId: number;
  folderName: string;
  resource_responses: object;
}

export interface Course {
  id: number;
  academicLevel: string;
  classSize: string;
  studentDept: string;
  status: string;
  courseCode: string;
  courseTitle: string;
  reasonDelete: string;
  semester: string;
  year: string;
  additionalInformation: string;
  location: string;
  createdAt: string;
  updatedAt: string;
}

export interface Feedback {
  id: number;
  message: string;
  display: boolean;
  createdAt: string;
  updatedAt: string;
  userId: number;
  rating: string;
}

export interface FeedbackComment {
  id: number;
  message: string;
  linkedResourceId: number;
  linkedResourceType: string;
  createdAt: string;
  updatedAt: string;
  userId: number;
}

export interface ResourceRequest {
  id: number;
  type: string;
  description: string;
  status: string;
  reasonDelete: string;
  createdAt: string;
  updatedAt: string;
  userId: number;
  resource_responses: object;
}

export interface FAQ {
  id: number;
  userQuestion: string;
  answer: string;
  display: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface ContactUs {
  id: number;
  firstName: string;
  lastName: string;
  nameOrg: boolean;
  email: string;
  phoneNumber: string;
  message: string;
  createdAt: string;
  updatedAt: string;
}

export interface Organization {
  id: number;
  name: string;
  originalName: string;
  display: boolean;
  profileUpdate: boolean;
  status: string;
  createdAt: string;
  updatedAt: string;
}

export interface CourseRequestFilters {
  courseRequestType: string[];
  insType: string[];
  gender: string[];
  location: string[];
  state: string[];
  topicCategory: string[];
  yearsOfExp: string[];
  ageRange: string[];
  title: string[];
}

export interface PracAvailabilityFilters {
  topicCategory: string[];
  courseRequestType: string[];
  gender: string[];
  size: string[];
  type: string[];
  expertise: string[];
  location: string[];
  state: string[];
}

export type Filters = CourseRequestFilters | PracAvailabilityFilters;

export interface DisplayData {
  userType: string;
  color: string;
  label: string;
  title: string;
  filters: Filters;
  data: [];
  count: number;
  markers: [];
  groups: Record<string, []>;
}

export interface DownloadData {
  userType: string;
  title: string;
  count: number;
  city: string;
  state: string;
  groupCount: number;
}

export interface Category {
  id: number;
  name: string;
  table: string;
}

export interface Topic {
  id: number;
  name: string;
  categoryId: number;
  categoryName: string;
  table: string;
  category: Category;
}
