<template>
  <div id="popup_alert" class="popup-msg" v-show="visible">
    <div class="content-body">
      <div class="popup-header">
        <div class="content-title pb-3 pt-1">
          <slot name="title"></slot>
        </div>
        <div>
          <button type="button" class="close-btn mr-4" @click="$emit('close')">
            <font-awesome-icon
              icon="fa-solid fa-xmark"
              class="close-btn-icon"
            />
          </button>
        </div>
      </div>

      <div id="popup_alert_body" class="content-msg">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style scoped>
.popup-msg {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 10;
}

.content-body {
  background-color: #fff;
  max-width: 800px;
  border-radius: 6px;
  padding: 15px 10px 15px 20px;
  margin: 10% auto;
}

.popup-header {
  display: grid;
  grid-template-columns: 1fr 15px;
}

.close-btn {
  border: none;
  color: #1c1e21;
  background-color: #fff;
  position: relative;
}

.close-btn:hover {
  color: red;
}

.close-btn-icon {
  padding: 0.2rem;
  font-size: larger;
  width: 20px;
  height: 20px;
}

.popup-msg .content-body {
  padding: 15px;
  max-width: 500px;
}

.content-title {
  font-weight: bold;
  font-size: 1.25rem;
  text-align: center;
  line-height: 1.2;
  border-bottom: 2px solid rgb(240, 240, 240);
}

.popup-msg .content-msg {
  margin: 20px 0;
}

.popup-acknowledge .content-body .content-msg {
  text-align: left;
  padding: 0px 25px;
  margin-top: 10px;
}

.hidden {
  display: none;
}
</style>
