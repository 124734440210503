import { useToast } from "vue-toast-notification";
import { Course, CourseRequest } from "@/types";
const toast = useToast();

// export const websiteURL = "http://localhost:8080/";
// export const websiteURL = "http://conpec.mlsoc.vt.edu:8085/";
export const websiteURL = "https://conpec.mlsoc.vt.edu/";

export const longMonthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export function checkIfFilesAreTooBig(value, contest, files?: File[]): boolean {
  let valid = true;
  if (files) {
    files.map((file) => {
      const size = file.size / 1024 / 1024;
      if (size > 25) {
        valid = false;
      }
    });
  }
  return valid;
}

export const isJSONObject = (jsonString) => {
  try {
    const o = JSON.parse(jsonString);
    if (o && typeof o === "object") {
      return true;
    }
  } catch (e) {
    return false;
  }
  return false;
};

export const formatSubjectSpeak = (s, o) => {
  const subjectSpeak = JSON.parse(s || "[]");
  const subjectSpeakOthers = JSON.parse(o || "[]");
  if (subjectSpeak.length == 0 && subjectSpeakOthers.length == 0) return "N/A";
  const index = subjectSpeak.indexOf("Others");
  if (index > -1) {
    subjectSpeak.splice(index, 1);
    return subjectSpeak.concat(subjectSpeakOthers).join(", ");
  } else return subjectSpeak.join(", ");
};

export const sameDate = (s, d, t) => {
  if (s && d && t)
    return new Date(s).getTime() == new Date(d + "T" + t).getTime();
  else return false;
};

export const formatTime = (t) => {
  if (t == null || t == undefined) return;
  const hours = t.split(":")[0];
  return hours > 12 ? hours - 12 + ":" + t.split(":")[1] + " PM" : t + " AM";
};

export const formatDate = (d) => {
  const [year, month, day] = d.split("-");
  return new Date(+year, +month - 1, +day).toLocaleDateString();
};

export const displayLabels = (values) => {
  const value = JSON.parse(JSON.stringify(values)).map((x) => x["label"]);
  return value.join(", ");
};

export const groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const mulGroupBy = function (xs, f) {
  // return xs.reduce(function (rv, x) {
  //   console.log(rv);
  //   console.log(x);
  //   const key = x[key1] + "-" + x[key2];
  //   console.log(key);
  //   (rv[x[key]] = rv[x[key]] || []).push(x);
  //   return rv;
  // }, {});
  const groups = {};
  xs.forEach(function (o) {
    const group = JSON.stringify(f(o));
    groups[group] = groups[group] || [];
    groups[group].push(o);
  });
  return groups;
};

export const getTimeDiff = (date) => {
  const millisecondsDifference: number = +new Date() - +new Date(date);
  // Calculate the various time units
  const millisecondsInMinute = 1000 * 60;
  const millisecondsInHour = millisecondsInMinute * 60;
  const millisecondsInDay = millisecondsInHour * 24;
  const millisecondsInWeek = millisecondsInDay * 7;
  const millisecondsInYear = millisecondsInDay * 365.25;

  if (millisecondsDifference >= millisecondsInYear) {
    const years = Math.floor(millisecondsDifference / millisecondsInYear);
    return `${years}y`;
  } else if (millisecondsDifference >= millisecondsInWeek) {
    const weeks = Math.floor(millisecondsDifference / millisecondsInWeek);
    return `${weeks}w`;
  } else if (millisecondsDifference >= millisecondsInDay) {
    const days = Math.floor(millisecondsDifference / millisecondsInDay);
    return `${days}d`;
  } else if (millisecondsDifference >= millisecondsInHour) {
    const hours = Math.floor(millisecondsDifference / millisecondsInHour);
    return `${hours}h`;
  } else {
    const minutes = Math.floor(millisecondsDifference / millisecondsInMinute);
    return `${minutes}m`;
  }
};

export const validateOptions = (e, s, k) => {
  if (e[0] === k && e.length > 1) {
    s.remove(k);
  } else if (e[e.length - 1] === k && e.length > 1) {
    s.clear();
    s.select(k);
  }
};

export const validateOptNone = (e, s) => {
  validateOptions(e, s, "None");
};
export const validateOptAll = (e, s) => {
  validateOptions(e, s, "All");
};
export const validateOptNoPref = (e, s) => {
  validateOptions(e, s, "No preference");
};

export const onInvalidSubmit = ({ errors }) => {
  Object.values(errors).forEach((error) => showErrorToast(error));
};

export const getTimezoneName = () => {
  const today = new Date();
  const short = today.toLocaleDateString(undefined);
  const full = today.toLocaleDateString(undefined, { timeZoneName: "long" });

  // Trying to remove date from the string in a locale-agnostic way
  const shortIndex = full.indexOf(short);
  if (shortIndex >= 0) {
    const trimmed =
      full.substring(0, shortIndex) + full.substring(shortIndex + short.length);
    // by this time `trimmed` should be the timezone's name with some punctuation -
    // trim it from both sides
    return trimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, "");
  } else {
    // in some magic case when short representation of date is not present in the long one, just return the long one as a fallback, since it should contain the timezone's name
    return full;
  }
};

export const responseParser = (availability, response, availabilityObjects) => {
  for (const key in response) {
    if (availabilityObjects.includes(key)) {
      availability[key] = JSON.parse(response[key]) || '[""]';
    } else {
      availability[key] = response[key];
    }
  }
};

export const convertCourseToCourseRequest = (course: Course) => {
  const courseRequest = {} as CourseRequest;
  courseRequest.courseCode = course.courseCode;
  courseRequest.courseTitle = course.courseTitle;
  courseRequest.academicLevel = course.academicLevel;
  courseRequest.studentDept = course.studentDept;
  courseRequest.classSize = course.classSize;
  courseRequest.additionalInformation = course.additionalInformation;
  courseRequest.location = course.location;
  courseRequest.semester = course.semester;
  courseRequest.year = course.year;
  return courseRequest;
};

export const showVisualRep = (value) => {
  if (value === "Labs" || value === "Site Visit" || value === "Workshop") {
    return true;
  }
  return false;
};

export const showPriorKnowledge = (value) => {
  if (
    value === "Guest Lecture" ||
    value === "Seminar" ||
    value === "Workshop"
  ) {
    return true;
  }
  return false;
};

export const showLocation = (value) => {
  if (
    value === "Guest Lecture" ||
    value === "Seminar" ||
    value === "Workshop" ||
    value === "Judge for Project" ||
    value === "Sponsor for Project" ||
    value === "Labs"
  ) {
    return true;
  }
  return false;
};

export const isCapstoneTermProject = (value) => {
  if (
    value === "Judge for Project" ||
    value === "Mentor for Project" ||
    value === "Sponsor for Project"
  ) {
    return true;
  }
  return false;
};

export const showClassroomItems = (value) => {
  if (
    value === "Guest Lecture" ||
    value === "Seminar" ||
    value === "Workshop" ||
    value === "Labs"
  ) {
    return true;
  }
  return false;
};

export const showAssignmentConnected = (value) => {
  if (
    value === "Guest Lecture" ||
    value === "Seminar" ||
    value === "Site Visit" ||
    value === "Workshop" ||
    value === "Labs"
  ) {
    return true;
  }
  return false;
};

export const showInfoToast = (msg) => {
  toast.info(msg, {
    position: "top-right",
    duration: 5000,
  });
};

export const showToast = (msg) => {
  toast.success(msg, {
    position: "top-right",
    duration: 5000,
  });
};

export const showErrorToast = (msg) => {
  toast.error(msg, {
    position: "top-right",
    duration: 5000,
    type: "error",
  });
};

export const valid = (e) => {
  return e && e.length > 0;
};

export const courseReqTypeOptions = [
  "Site Visit",
  "Seminar",
  "Workshop",
  "Guest Lecture",
  "Judge for Project",
  "Mentor for Project",
  "Sponsor for Project",
  "Labs",
];

export const physicalDisOptions = [
  "None",
  "Mobility impairment",
  "Hearing impairment",
  "Vision impairment",
  "Speech impairment",
  "Autism",
  "Attention-deficit/hyperactivity disorder (ADHD)",
  "Others",
];
export const genderOptions = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
  { label: "Non-binary", value: "Non-binary" },
  { label: "Others, please specify", value: "Others" },
];

export const ethnicityOptions = [
  {
    label: "American Indian or Alaska Native",
    value: "American Indian or Alaska Native",
  },
  { label: "Asian", value: "Asian" },
  { label: "Black or African American", value: "Black or African American" },
  { label: "Hispanic or Latinx", value: "Hispanic or Latinx" },
  { label: "Middle Eastern", value: "Middle Eastern" },
  {
    label: "Native Hawaiian or other Pacific Islander",
    value: "Native Hawaiian or other Pacific Islander",
  },
  { label: "White/Caucasian", value: "White/Caucasian" },
  { label: "Others, please specify", value: "Others" },
];

export const safetyReqOptions = [
  { label: "All", value: "All" },
  { label: "Not Required", value: "Not Required" },
  { label: "Face mask", value: "Face mask" },
  { label: "Face shield", value: "Face shield" },
  { label: "Glove", value: "Glove" },
  { label: "Hardhat", value: "Hardhat" },
  { label: "Life vest", value: "Life vest" },
  { label: "Safety boot", value: "Safety boot" },
  { label: "Safety glasses", value: "Safety glasses" },
  { label: "Safety vest", value: "Safety vest" },
  { label: "Others please specify", value: "Others" },
];

export const projectStageOptions = [
  { label: "Site layout", value: "Site layout" },
  { label: "Excavation", value: "Excavation" },
  { label: "Foundation", value: "Foundation" },
  { label: "Casting of beams and slabs", value: "Casting of beams and slabs" },
  {
    label: "Concreting and Brick masonry",
    value: "Concreting and Brick masonry",
  },
  { label: "Flooring", value: "Flooring" },
  { label: "Rough framing", value: "Rough framing" },
  {
    label: "Mechanical Electrical and Plumbing",
    value: "Mechanical Electrical and Plumbing",
  },
  { label: "Drywall Installation", value: "Drywall Installation" },
  { label: "Roofing", value: "Roofing" },
  { label: "Finishing", value: "Finishing" },
  { label: "Glove", value: "Glove" },
  { label: "Demolition", value: "Demolition" },
  { label: "Others please specify", value: "Others" },
];

export const countryOptions = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua & Deps",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Central African Rep",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo",
  "Congo {Democratic Rep}",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "East Timor",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland {Republic}",
  "Israel",
  "Italy",
  "Ivory Coast",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea North",
  "Korea South",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macedonia",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar, {Burma}",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "St Kitts & Nevis",
  "St Lucia",
  "Saint Vincent & the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome & Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Togo",
  "Tonga",
  "Trinidad & Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

export const stateOptions = [
  { label: "Alabama", value: "AL", region: "Southeast" },
  { label: "Alaska", value: "AK", region: "West" },
  { label: "Arizona", value: "AZ", region: "Southwest" },
  { label: "Arkansas", value: "AR", region: "Southeast" },
  { label: "California", value: "CA", region: "West" },
  { label: "Colorado", value: "CO", region: "West" },
  { label: "Connecticut", value: "CT", region: "Northeast" },
  { label: "Delaware", value: "DE", region: "Northeast" },
  { label: "District Of Columbia", value: "DC", region: "Southeast" },
  { label: "Florida", value: "FL", region: "Southeast" },
  { label: "Georgia", value: "GA", region: "Southeast" },
  { label: "Hawaii", value: "HI", region: "West" },
  { label: "Idaho", value: "ID", region: "West" },
  { label: "Illinois", value: "IL", region: "Midwest" },
  { label: "Indiana", value: "IN", region: "Midwest" },
  { label: "Iowa", value: "IA", region: "Midwest" },
  { label: "Kansas", value: "KS", region: "Midwest" },
  { label: "Kentucky", value: "KY", region: "Southeast" },
  { label: "Louisiana", value: "LA", region: "Southeast" },
  { label: "Maine", value: "ME", region: "Northeast" },
  { label: "Maryland", value: "MD", region: "Northeast" },
  { label: "Massachusetts", value: "MA", region: "Northeast" },
  { label: "Michigan", value: "MI", region: "Midwest" },
  { label: "Minnesota", value: "MN", region: "Midwest" },
  { label: "Mississippi", value: "MS", region: "Southeast" },
  { label: "Missouri", value: "MO", region: "Midwest" },
  { label: "Montana", value: "MT", region: "West" },
  { label: "Nebraska", value: "NE", region: "Midwest" },
  { label: "Nevada", value: "NV", region: "West" },
  { label: "New Hampshire", value: "NH", region: "Northeast" },
  { label: "New Jersey", value: "NJ", region: "Northeast" },
  { label: "New Mexico", value: "NM", region: "Southwest" },
  { label: "New York", value: "NY", region: "Northeast" },
  { label: "North Carolina", value: "NC", region: "Southeast" },
  { label: "North Dakota", value: "ND", region: "Midwest" },
  { label: "Northern Mariana Islands", value: "MP" },
  { label: "Ohio", value: "OH", region: "Midwest" },
  { label: "Oklahoma", value: "OK", region: "Southwest" },
  { label: "Oregon", value: "OR", region: "West" },
  { label: "Pennsylvania", value: "PA", region: "Northeast" },
  { label: "Rhode Island", value: "RI", region: "Northeast" },
  { label: "South Carolina", value: "SC", region: "Southeast" },
  { label: "South Dakota", value: "SD", region: "Midwest" },
  { label: "Tennessee", value: "TN", region: "Southeast" },
  { label: "Texas", value: "TX", region: "Southwest" },
  { label: "Utah", value: "UT", region: "West" },
  { label: "Vermont", value: "VT", region: "Northeast" },
  { label: "Virginia", value: "VA", region: "Southeast" },
  { label: "Washington", value: "WA", region: "West" },
  { label: "West Virginia", value: "WV", region: "Southeast" },
  { label: "Wisconsin", value: "WI", region: "Midwest" },
  { label: "Wyoming", value: "WY", region: "West" },
];
export const insDeliveryTypeOptions = [
  "In-person",
  "Virtual",
  "Virtual or In-person",
];
export const insDeliveryTypeSiteVisitOptions = [
  "On-site",
  "Virtual site visit",
  "Virtual site visit or On-site",
];
export const typeOfProjectOptions = [
  "Capstone Project",
  "Course/Classroom/Term project",
];
export const areaOfExpertiseOptions = [
  "Architectural Engineering",
  "Architecture",
  "Building and Construction Inspection",
  "Building Control",
  "Carpenter",
  "Civil Engineering",
  "Construction Data Analytics",
  "Construction Management",
  "Drone Operator",
  "Dry Wall and Ceiling Tile Installer",
  "Electrical Engineering",
  "Electrician",
  "Estimating",
  "Executive/Legal",
  "Facilities Management & Building Operation",
  "Geotechnical Engineering",
  "Glazier",
  "Health and Safety",
  "HVAC",
  "Landscape Architecture",
  "Mason",
  "Mechanical Engineering",
  "MEP Specialist",
  "MEP works",
  "Operation Management",
  "Owner's Representative",
  "Painter",
  "Plumber",
  "Project Management",
  "Real Estate Development",
  "Roofer",
  "Scheduler",
  "Site Management",
  "Site Superintendent",
  "Smart Building",
  "Structural Engineering",
  "Virtual Design Construction Manager",
];
export const univOptions = [
  "University of Alaska",
  "Alaska Christian College",
  "National Park Community College",
  "University of Alaska Fairbanks",
  "University of Arkansas – Fort Smith",
  "Alabama A&M University",
  "Athens State University",
  "Auburn University",
  "Auburn University at Montgomery",
  "Birmingham-Southern College",
  "Calhoun Community College",
  "Enterprise State Community College",
  "Faulkner State Community College",
  "Gadsden State  Community College",
  "Huntingdon College",
  "Jacksonville State University",
  "Lurleen B. Wallace Community College",
  "University of Montevallo",
  "Northeast Alabama Community College",
  "Northwest-Shoals Community College",
  "Oakwood University",
  "Samford University",
  "Southeastern Bible College",
  "University of South Alabama",
  "Trenholm State Technical College",
  "Troy University",
  "Tuskegee University",
  "University of Alabama",
  "University of Alabama at Birmingham",
  "University of Alabama in Huntsville",
  "University of North Alabama",
  "Snead State Community College",
  "Arkansas Northeastern College",
  "Arkansas State University",
  "Arkansas Tech University",
  "Central Baptist College",
  "Harding University",
  "Hendrix College",
  "Henderson State University",
  "John Brown University",
  "Lyon College",
  "Mid-South Community College",
  "North Arkansas College",
  "Northwest Arkansas Community College",
  "Northwest Technical Institute",
  "Ouachita Baptist University",
  "University of the Ozarks",
  "Phillips Community College U of A",
  "Pulaski Technical College",
  "Southern Arkansas University Magnolia",
  "Southeast Arkansas College",
  "University of Arkansas Community College Batesville",
  "University of Arkansas at Little Rock",
  "University of Arkansas for Medical Sciences",
  "University of Arkansas at Pine Bluff",
  "University of Arkansas",
  "University of Central Arkansas",
  "Williams Baptist College",
  "University of Arizona",
  "Arizona State University",
  "Arizona Western College",
  "Bryan University",
  "Central Arizona College",
  "Glendale Community College",
  "Grand Canyon University",
  "Maricopa Community Colleges",
  "Mesa Community College",
  "Northern Arizona University",
  "Northland Pioneer  College",
  "University of Phoenix",
  "Phoenix College",
  "Pima Community College",
  "Prescott College",
  "Rio Salado College",
  "South Mountain Community College",
  "University of Advancing Technology",
  "Yavapai College",
  "Academy of Arts University",
  "Adance Computing Institute",
  "Acupuncture & Integrative Medicine College",
  "Alliant International University",
  "Allied American University",
  "Anaheim University",
  "Angeles College",
  "Antioch University Los Angeles",
  "Argosy University",
  "Art Center College of Design",
  "The Art Institute",
  "Ashford University",
  "ATI College",
  "Antelope Valley College",
  "Bakersfield College",
  "Barstow Community College",
  "Berkeley University of California",
  "Berkeley City College",
  "Brandman University",
  "Butte College",
  "Cabrillo College",
  "California Institute of the Arts",
  "California Baptist University",
  "California Lutheran University",
  "California Miramar University",
  "California Polytechnic State University",
  "California State University",
  "California Institute of Technology",
  "Canada College",
  "College of the Canyons",
  "California College of the Arts",
  "California Community Colleges Chanellor’s Office",
  "City College of San Francisco",
  "Cerritos College",
  "Cerro Coso Community College",
  "Claremont Graduate University",
  "Chaffey College",
  "Chapman University",
  "California Institute of Integral Studies",
  "Citrus College",
  "The Claremont Colleges",
  "Claremont McKenna College",
  "Copper Mountain College",
  "Cogswell Polytechnical College",
  "College of San Mateo",
  "College of the Desert",
  "El Camino College Compton Center",
  "Contra Costa College",
  "College of the Sequoias",
  "Crafton Hills College",
  "California State University Bakersfield",
  "California State University Channel Islands",
  "California State University Dominguez Hills",
  "California State University East Bay",
  "Fresno State",
  "California State University Long Beach",
  "California State University Monterey Bay",
  "California State University Northridge",
  "California State Polytechnic University Pomona",
  "California State University Sacramento",
  "California State University San Bernardino",
  "California State University San Marcos",
  "California State University Stanislaus",
  "Cuesta College",
  "Cuyamaca College",
  "California Western School of Law San Diego",
  "Cypress College",
  "DeAnza College",
  "San Joaquin Delta College",
  "Dominican University of California",
  "Dominican School of Philosophy and Theology",
  "Diablo Valley College",
  "East Los Angeles College",
  "El Camino College",
  "Ex’pression College",
  "Foothill-De Anza Community College District",
  "Fashion Institute of Design & Merchandising",
  "Fielding Graduate University",
  "Foothill College",
  "Fresno Pacific University",
  "Fresno City College",
  "Frederick Taylor University",
  "Fullerton  College",
  "Fuller Theological Seminary",
  "California State University Fullerton",
  "Gavilan College",
  "Golden Gate Baptist Theological Seminary",
  "Glendale Community College",
  "Gnomon School of Visual Effects",
  "Columbia College",
  "Golden West College",
  "Grossmont College",
  "Allan Hancock College",
  "Hartnell College",
  "Henley-Putnam University",
  "Harvey Mudd College",
  "Holy Names University",
  "Humboldt State University",
  "Humphreys College",
  "Imperial Valley College",
  "International Sports Sciences Association",
  "International Technological University",
  "Irvine Valley College",
  "William Jessup University",
  "John F. Kennedy University",
  "Keck Graduate Institute",
  "Mount Saint Mary’s College",
  "Los Angeles Community College District",
  "Los Angeles City College",
  "Los Angeles Harbor College",
  "Los Angeles Mission College",
  "Laney  College",
  "Los Angeles Southwest College",
  "La Sierra University",
  "Las Positas College",
  "Los Angeles Trade-Tech Community College",
  "Laurus College",
  "Los Angeles Valley College",
  "University of La Verne",
  "UCLA School of Law",
  "Long Beach City College",
  "Life Chiropractic College West",
  "Loma Linda University",
  "Loyola Marymount University",
  "Los Medanos College",
  "Los Rios Community College District",
  "Lake Tahoe Community College",
  "College of Marin",
  "Marymount California University",
  "The Master’s College",
  "Merced Community College",
  "Mills College",
  "MiraCosta College",
  "Modesto Junior College",
  "Mount San Jacinto College",
  "Mount San Antonio College",
  "Napa Valley College",
  "Notre Dame de Namur University",
  "NewSchool of Architecture and Design",
  "Norco College",
  "Naval Postgraduate School",
  "Ohlone College",
  "Orange Coast College",
  "Otis College of Art and Design",
  "Occidental College",
  "University of the Pacific",
  "Pacific College of Oriental Medicine",
  "Palomar College",
  "Pasadena City College",
  "Pepperdine University",
  "Peralta Community College District",
  "Pierce College",
  "Pitzer College",
  "Pacific Lutheran  Theological Seminary",
  "Point Loma Nazarene University",
  "Pomona College",
  "Porterville College",
  "Pacific School of Religion",
  "Riverside City College",
  "Riverside Community College District",
  "University of Redlands",
  "College of the Redwoods",
  "Rio Hondo College",
  "Rosemead College",
  "Santa Ana College",
  "Saddleback College",
  "University of San Diego",
  "Santa Rosa Junior College",
  "Santa Barbara City College",
  "School of Continuing Education",
  "Scripps College",
  "Santa Clara University",
  "Southern California University of Health Sciences",
  "San Diego City College",
  "San Diego Mesa College",
  "San Diego State University",
  "San Francisco State University",
  "Shasta College",
  "The Institute of Buddhist Studies",
  "Sierra College",
  "Simpson University",
  "College of the Siskiyous",
  "San Jose State University",
  "Starr King School for the Ministry",
  "Skyline College",
  "Santa Monica College",
  "San Mateo County Community College",
  "Soka University of America",
  "Solano Community College",
  "Sonoma State University",
  "Stanford University",
  "Saint Mary’s College",
  "Taft College",
  "Touro University California",
  "University of California Davis",
  "University of California Hastings College of the Law",
  "University of California Irvine",
  "University of California Los Angeles",
  "University of California Los Angeles Extension",
  "University of California Merced",
  "University of California",
  "University of California Press",
  "University of California Riverside",
  "University of California Santa Barbara",
  "University of California Santa Cruz",
  "University of California Santa Cruz Silicon Valley Extension",
  "University of California San Diego",
  "University of California San Francisco",
  "University of California",
  "University of the Pacific",
  "University of Southern California",
  "University of San Francisco",
  "San Bernardino Valley College",
  "Ventura College",
  "Victor Valley College",
  "West Coast Baptist College",
  "Western University of Health Sciences",
  "Westmont College",
  "West Valley College",
  "West Hills Community College District",
  "Whittier College",
  "West Los Angeles College",
  "Yuba Community College District",
  "Yosemite Community College District",
  "Unitek College",
  "Vanguard University",
  "Southwestern College",
  "Monterey Institute of International Studies",
  "Adams State University",
  "Auraria Higher Education Center",
  "Aims Community College",
  "Arapahoe Community College",
  "Community College of Aurora",
  "Community College of Denver",
  "Colorado Heights University",
  "University of Colorado Boulder",
  "Colorado College",
  "Colorado Mesa University",
  "Colorado State University",
  "Colorado State University-Pueblo",
  "University of Denver",
  "Fort Lewis College",
  "Front Range Community College",
  "Jones International University",
  "Colorado School of Mines",
  "Metropolitan State University of Denver",
  "Naropa University",
  "Pikes Peak Community College",
  "Pueblo Community College",
  "Redstone College",
  "Regis University",
  "University of the Rockies",
  "Red Rocks Community College",
  "William Howard Taft University",
  "University Corporation for Atmospheric Research",
  "University of Colorado Colorado Springs",
  "University of Colorado Denver",
  "University of Northern Colorado",
  "University of Colorado",
  "Western State Colorado University",
  "Albertus Magnus College",
  "Asnuntuck Community College",
  "Connecticut College",
  "Eastern Connecticut State University",
  "Fairfield University",
  "Goodwin College",
  "University of Hartford",
  "Hartford Seminary",
  "University of New Haven",
  "Queens University of Charlotte",
  "Quinebaug Valley Community College",
  "Sacred Heart University",
  "Southern Connecticut State University",
  "Three Rivers Community College",
  "Trinity College",
  "Tunxis Community College",
  "University of Connecticut Health Center",
  "University of Connecticut",
  "University of Saint Joseph Connecticut",
  "Western Connecticut State University",
  "Wesleyan University",
  "Yale University",
  "American University",
  "Carnegie Institution for Science",
  "The Catholic University of America",
  "Gallaudet University",
  "Georgetown University",
  "The George Washington University",
  "Howard University",
  "Johns Hopkins School of Advanced International Studies",
  "University of California Washington Center",
  "University of the District of Columbia",
  "Delaware State University",
  "Delaware Technical Community College",
  "Goldey-Beacom College",
  "University of Delaware",
  "Wesley College",
  "Wilmington University",
  "Carlos Albizu University",
  "Acupuncture Massage College",
  "Atlantic Institute of Oriental Medicine",
  "Barry University",
  "Broward College",
  "College of Central Florida",
  "Chipola College",
  "Bethune-Cookman University",
  "Dade Medical College",
  "Daytona State College",
  "Eckerd College",
  "Edison State College",
  "Embry-Riddle Aeronautical University",
  "Everglades University",
  "Florida Atlantic University",
  "Florida Gulf Coast University",
  "Florida Institute of Technology",
  "Florida International University",
  "Flagler College",
  "Florida Southern College",
  "Florida National University",
  "Florida State College at Jacksonville",
  "Florida State University",
  "Gulf Coast State College",
  "Hillsborough Community College",
  "Jacksonville University",
  "Keiser University",
  "Loraines Academy Incorporated",
  "Lake Sumter State College",
  "Lynn University",
  "Millennia Atlantic University",
  "Miami Dade College",
  "University of Miami",
  "Miami International University of Art and Design",
  "New College of Florida",
  "North Florida Community College",
  "Nova Southeastern University",
  "National University of Health Sciences",
  "Northwest Florida State College",
  "Palm Beach State College",
  "Palm Beach Atlantic University",
  "Pensacola State College",
  "Pasco-Hernando State College",
  "Ringling College of Art and Design",
  "Rollins College",
  "Saint Leo University",
  "State College of Florida",
  "Seminole State College of Florida",
  "Santa Fe College",
  "South Florida State College",
  "Space Coast Health Institute",
  "Saint Petersburg College",
  "Stetson University",
  "Saint Thomas University",
  "Saint Vincent de Paul Regional Seminary",
  "Traviss Career Center",
  "University of Central Florida",
  "University of Florida",
  "University of North Florida",
  "University of South Florida",
  "University of South Florida Sarasota-Manatee",
  "University of South Florida Saint Petersburg",
  "University of Tampa",
  "University of West Florida",
  "Valencia College",
  "Warner University",
  "Webber International University",
  "Agnes Scott College",
  "Albany Technical College",
  "Armstrong Atlantic State University",
  "Atlanta Technical College",
  "Atlanta Metropolitan State College",
  "Berry College",
  "Carver College",
  "Child Care Education Institute",
  "Central Georgia Technical College",
  "Clayton State University",
  "Columbus State University",
  "Columbus Technical College",
  "Covenant College",
  "Dalton State",
  "Darton State College",
  "East Georgia State College",
  "Emory University",
  "Georgia Institute of Technology",
  "Georgia College",
  "Georgia Southern University",
  "Georgia Gwinnett College",
  "Georgia Perimeter College",
  "Augusta University",
  "Georgia State University",
  "Georgia South Western State University",
  "Interactive College of Technology",
  "Atlanta’s John Marshall Law School",
  "Kennesaw State University",
  "Lanier Technical College",
  "Life University",
  "Mercer University",
  "Middle Georgia State College",
  "Morehouse College",
  "Morehouse School of Medicine",
  "North Georgia Technical College",
  "Oglethorpe University",
  "Okefenokee Technical College",
  "Paine College",
  "Pfeiffer University",
  "Piedmont College",
  "Reinhardt University",
  "Savannah State University",
  "Savannah College of Art and Design",
  "Shorter University",
  "Southeastern Technical College",
  "Spelman College",
  "Southern Polytechnic State University",
  "Thomas University",
  "University of Georgia",
  "University of North Georgia",
  "University System of Georgia",
  "Valdosta State University",
  "University of West Georgia",
  "Wiregrass Georgia Technical College",
  "Brigham Young University Hawaii",
  "Chaminade University",
  "University of Hawaii at Manoa",
  "Mid-Pacific Institute",
  "AIB College of Business",
  "Central College",
  "Coe College",
  "Cornell College",
  "Des Moines Area Community College",
  "Dordt College",
  "Drake University",
  "Divine Word College",
  "Eastern Iowa Community Colleges",
  "Grinnell College",
  "Hawkeye Community College",
  "Iowa State University",
  "Iowa Central Community College",
  "Iowa Wesleyan College",
  "Iowa Western Community College",
  "Kaplan University",
  "Kirkwood Community College",
  "La’ James College",
  "Loras College",
  "Luther College",
  "Maharishi University of Management",
  "Northeast Iowa Community  College",
  "Palmer College of Chiropractic",
  "Saint Ambrose University",
  "Simpson College",
  "Southwestern Community College",
  "Univeristy of Iowa",
  "Upper Iowa University",
  "University of Northern Iowa",
  "Wartburg College",
  "Waldorf College",
  "Boise State University",
  "Brigham Young University Idaho",
  "The College of Idaho",
  "College of Southern Idaho",
  "Idaho State University",
  "University of Idaho",
  "Augustana College",
  "Aurora University",
  "Benedictine University",
  "Bradley University",
  "City Colleges of Chicago",
  "The University of Chicago Booth School of Business",
  "College of Lake County",
  "College of DuPage",
  "Columbia College Chicago",
  "Danville Area Community College",
  "DePaul University",
  "Dominican University",
  "Eastern Illinois University",
  "Elmhurst College",
  "Governors State",
  "Greenville College",
  "The Hadley School for the Blind",
  "Harper College",
  "Heartland Community College",
  "Illinois Central College",
  "Illinois College of Optometry",
  "Illinois Institute of Technology",
  "University of Illinois at Urbana-Champaign",
  "Illinois State University",
  "Illinois Mathematics and Science Academy",
  "Illinois Valley Community College",
  "Illinois Wesleyan University",
  "John A. Logan College",
  "Joliet Junior College",
  "The John Marshall Law School",
  "Judson University",
  "Kaskaskia College",
  "Kankakee Community College",
  "IIT Chicago-Kent College of Law",
  "Kishwaukee College",
  "Knox College",
  "Lake Forest College",
  "Lakeview College of Nursing",
  "Lewis and Clark Community College",
  "Lewis University",
  "Lincoln Land Community College",
  "Loyola University Chicago",
  "Midwestern Career College",
  "McHenry County College",
  "McKendree University",
  "Midwestern University",
  "Monmouth College",
  "Moraine Valley Community College",
  "Morton College",
  "Northeastern Illinois University",
  "Northern Illinois University",
  "National Louis University",
  "North Park University",
  "Northwestern University",
  "Oakton Community College",
  "Olivet Nazarene University",
  "Chicago ORT Technical Institute",
  "Parkland College",
  "Prairie State College",
  "Principia College",
  "Resurrection University",
  "Robert Morris University Illinois",
  "Rockford University",
  "Rock Valley College",
  "Roosevelt University",
  "Rosalind Franklin University",
  "Saint Anthony College of Nursing",
  "School of the Art Institute of Chicago",
  "Saint Francis Medical Center College of Nursing",
  "Shawnee Community College",
  "Southeastern Illinois College",
  "Southern Illinois University",
  "Southern Illinois University Edwardsville",
  "Spoon River College",
  "South Suburban College",
  "University of Saint Francis",
  "Southwestern Illinois College",
  "Saint Xavier University",
  "Trinity Christian College",
  "University of Chicago",
  "University of Illinois at Chicago",
  "University of Illinois",
  "University of Illinois Springfield",
  "University of Illinois at Urbana-Champaign",
  "Waubonsee Community College",
  "Wheaton College",
  "Western Illinois University",
  "Saint John’s College",
  "Anabaptist Mennonite Biblical Seminary",
  "Anderson University",
  "Bethel College Indiana",
  "Brown Mackie College",
  "Ball State University",
  "Butler University",
  "DePauw University",
  "Earlham College",
  "University of Evansville",
  "Goshen College",
  "Grace College and Seminary",
  "Hanover College",
  "Harrison College",
  "Holy Cross College",
  "Indiana University",
  "Indiana State University",
  "Indiana Wesleyan University",
  "Indiana University – Purdue University Fort Wayne",
  "Indiana University",
  "Indiana University Bloomington",
  "Indiana University East",
  "Indiana University Northwest",
  "Indiana University – Purdue University Indianapolis",
  "Indiana University Southeast",
  "Indiana University South Bend",
  "Ivy Tech Community College",
  "Manchester University",
  "Marian University Indianapolis",
  "University of Notre Dame",
  "Oakland City University",
  "Purdue University North Central",
  "Purdue University",
  "Purdue University Calumet",
  "Rose-Hulman Institute of Technology",
  "Saint Mary’s College",
  "Trine University",
  "University of Indianapolis",
  "University of Southern Indiana",
  "Valparaiso University",
  "Wabash College",
  "Benedictine College",
  "Butler Community College",
  "Emporia State University",
  "Fort Hays State University",
  "Flint Hills Technical College",
  "Fort Scott Community College",
  "Garden City Community College",
  "Hesston College",
  "Highland Community College",
  "Johnson County Community College",
  "Kansas City Kansas Community College",
  "Kansas State University",
  "The University of Kansas",
  "McPherson College",
  "Neosho County Community College",
  "Newman University",
  "Pittsburg State University",
  "Saint Paul School of Theology and Ministry",
  "Sterling College",
  "University of Saint Mary",
  "Washburn University",
  "Washburn University School of Law",
  "Wichita State University",
  "Asbury University",
  "Bellarmine University",
  "Berea College",
  "Centre College",
  "Eastern Kentucky University",
  "Frontier Nursing University",
  "Georgetown College",
  "Kentucky Community and Technical College System",
  "Kentucky Christian University",
  "Kentucky State University",
  "Lindsey Wilson College",
  "The University of Louisville",
  "Midway College",
  "Morehead State University",
  "Murray State University",
  "Northern Kentucky University",
  "The Southern Baptist Theological Seminary",
  "Saint Catharine College",
  "Sullivan University",
  "University of the Cumberlands",
  "Western Kentucky University",
  "Bossier Parish Community College",
  "Centenary College of Louisiana",
  "Louisiana Tech University",
  "Louisiana Culinary Institute",
  "University of Louisiana Lafayette",
  "Loyola University New Orleans",
  "Louisiana State University",
  "Louisiana State University – Eunice",
  "LSU Health Sciences Center – New Orleans",
  "LSU Health Sciences Center – New Orleans",
  "Louisiana State University System",
  "Nicholls State University",
  "New Orleans Baptist Theological Seminary",
  "Nunez Community College",
  " Southeastern Louisiana University",
  "Southern University",
  "Tulane University",
  "University of Louisiana at Monroe",
  "University of New Orleans",
  "Xavier University of Louisiana",
  "Amherst College",
  "Assumption College",
  "Babson College",
  "Boston College",
  "Bentley University",
  "Berklee College of Music",
  "Berkshire Community College",
  "The Boston Conservatory",
  "Brandeis University",
  "Bridgewater State University",
  "Boston University",
  "Cambridge College",
  "Clark University",
  "Curry College",
  "Elms College",
  "Emerson College",
  "Emmanuel College",
  "Endicott College",
  "Fitchburg State University",
  "Framingham State University",
  "Gordon Conwell Theological Seminary",
  "Gordon College",
  "Hampshire College",
  "Harvard University",
  "Harvard Business School",
  "College of the Holy Cross",
  "Lesley University",
  "Massachusetts Maritime Academy",
  "Massachusetts Department of Higher Education",
  "Massachusetts College of Art and Design",
  "MassBay Community College",
  "Marine Biological Laboratory",
  "Merrimack Education Center",
  "Merrimack College",
  "MGH Institute of Health Professions",
  "Milton Academy",
  "Massachusetts Institute of Technology",
  "Montserrat College of Art",
  "Mount Ida College",
  "Mount Holyoke College",
  "Middlesex Community College",
  "Middlesex School",
  "North Bennet Street School",
  "New England Law – Boston",
  "Northeastern University",
  "Olin College of Engineering",
  "Salem State University",
  "Simmons College",
  "Smith College",
  "Spa Tech Institute",
  "Springfield College",
  "Springfield Technical Community College",
  "Suffolk University",
  "Boston Architectural College",
  "Tufts University",
  "University of Massachusetts Amherst",
  "University of Massachusetts Dartmouth",
  "University of Massachusetts Medical School",
  "University of Massachusetts",
  "University of Massachusetts Boston",
  "University of Massachusetts Lowell",
  "Wellesley College",
  "Wheaton College, Norton",
  "Wheelock College",
  "Williams College",
  "Western New England University",
  "Worcester State University",
  "Worcester Polytechnic Institute",
  "Anne Arundel Community College",
  "Baltimore City Community College",
  "Bowie State University",
  "Carroll Community College",
  "The Community College of Baltimore County",
  "Chesapeake College",
  "Coppin State University",
  "College of Southern Maryland",
  "Frederick Community College",
  "Frostburg State University",
  "Garrett College",
  "Goucher College",
  "Hagerstown Community  College",
  "Harford Community College",
  "Hood College",
  "Howard Community College",
  "Johns Hopkins Bloomberg School of Public Health",
  "Johns Hopkins University",
  "Loyola University Maryland",
  "Montgomery Blair High School",
  "McDaniel College",
  "Maryland Institute College of Art",
  "Montgomery College",
  "Mount Saint Mary’s University",
  "Maryland University of Integrative Health",
  "Prince George’s Community College",
  "Salisbury University",
  "Sojourner-Douglass College",
  "Saint Mary’s College of Maryland",
  "Saint James School",
  "Towson University",
  "University of  Baltimore",
  "University of Maryland",
  "University of  Maryland Baltimore County",
  "University of Maryland",
  "University of Maryland University College",
  "University System of Maryland",
  "United States Naval Academy",
  "Washington College",
  "Wor-Wic Community College",
  "Saint John’s College",
  "Bates College",
  "Bowdoin College",
  "Central Maine Community College",
  "College of the Atlantic",
  "Colby College",
  "Husson University",
  "Maine Maritime Academy",
  "Northern Maine Community College",
  "Saint Joseph’s College",
  "Southern Maine Community College",
  "University of Maine",
  "University of Maine Fort Kent",
  "University of Maine at Presque Isle",
  "University of New England",
  "Unity College",
  "York County Community College",
  "University of Main at Augusta",
  "Adrian College",
  "Albion College",
  "Alma College",
  "Alpena Community College",
  "Andrews University",
  "Aquinas College",
  "Baker College",
  "Bay College",
  "Calvin College",
  "Central Michigan University",
  "College for Creative Studies",
  "Cornerstone University",
  "Davenport University",
  "Delta College",
  "Douglas J. Aveda Institute",
  "Eastern Michigan University",
  "Ferris State University",
  "Finlandia University",
  "Grand Rapids Community College",
  "Grand Valley State University",
  "Henry Ford College",
  "Hillsdale College",
  "Hope College",
  "Jackson College",
  "Kettering University",
  "Kalamazoo Valley Community College",
  "Kalamazoo College",
  "Lake Michigan College",
  "Lansing Community College",
  "Lake Superior State University",
  "Lawrence Technological University",
  "Macomb Community College",
  "Marygrove College",
  "Monroe County Community College",
  "Michigan State University",
  "Michigan Technological University",
  "Northwestern Michigan College",
  "Northern Michigan University",
  "Oakland Univeristy",
  "Oakland Community College",
  "Saginaw Chippewa Tribal College",
  "Saginaw Valley State University",
  "University of Detroit Mercy",
  "University of Michigan-Flint",
  "University of Michigan",
  "Wayne State",
  "Washtenaw Community College",
  "Western Michigan University",
  "Alexandria Technical & Community College",
  "Anoka-Ramsey Community College",
  "Augsburg College",
  "Bemidji State University",
  "Bethel University",
  "Carleton College",
  "Century College",
  "Central Lakes College",
  "Concordia College",
  "Crown College",
  "College of Saint Benedict, Saint John’s University",
  "Concordia University Saint Paul",
  "The College of Saint Scholastica",
  "Dakota County Technical College",
  "Gustavus Adolphus College",
  "Globe University",
  "Hamline University",
  "Inver Hills Community College",
  "Institute of Production and Recording",
  "Leech Lake Tribal College",
  "Luther Seminary",
  "Macalester College",
  "McNally Smith College of Music",
  "Mesabi Range College",
  "Metropolitan State University",
  "Minnesota State University Moorhead",
  "Minnesota State University Mankato",
  "North Hennepin Community College",
  "Normandale Community College",
  "Rasmussen College",
  "Ridgewater College",
  "Southwest Minnesota State University",
  "Saint Mary’s University of Minnesota",
  "South Central College",
  "Saint Cloud State  University",
  "Saint Olaf College",
  "University of Saint Thomas",
  "University of Minnesota",
  "United Theological Seminary of the Twin Cities",
  "University of Northwestern Saint Paul",
  "Walden University",
  "Winona State Unviversity",
  "Assemblies of God Theological Seminary",
  "Aquinas Institute of Theology",
  "A.T. Still University",
  "Avila University",
  "Columbia College",
  "Central Methodist University",
  "Crowder College",
  "Culver-Stockton College",
  "Drury University",
  "Evangel University",
  "Fontbonne University",
  "Hannibal-LaGrange",
  "Harris-Stowe State University",
  "Jefferson College",
  "Kansas City University of Medicine and Biosciences",
  "Lincoln University",
  "Lindenwood University",
  "Linn State Technical College",
  "Maryville University",
  "Metropolitan Community College",
  "Mineral Area College",
  "Mizzou University of Missouri",
  "Missouri State University",
  "Missouri Valley College",
  "Missouri Southern State  University",
  "Missouri University of Science and Technology",
  "North Central Missouri College",
  "Nazarene Theological Seminary",
  "Northwest Missouri State University",
  "Ozarks Technical Community College",
  "Park University",
  "Rockhurst University",
  "Southwest Baptist University",
  "South Central Career Center",
  "Southeast Missouri State University",
  "Saint Louis University",
  "Saint Charles Community College",
  "Saint Louis Community College",
  "Truman State University",
  "University of Central Missouri",
  "University of Missouri Kansas City",
  "University of Missouri Saint Louis",
  "University of Missouri System",
  "Webster University",
  "Westminster College, Missouri",
  "Washington University in Saint Louis",
  "Alcorn State University",
  "Belhaven University",
  "Delta State University",
  "East Mississippi Community College",
  "Jackson State University",
  "Mississippi Gulf Coast Community College",
  "Mississippi State University",
  "Northeast Mississippi Community College",
  "The University of Mississippi",
  "Pearl River Community College",
  "Reformed Theological Seminary",
  "University of Mississippi Medical Center",
  "University of Southern Mississippi",
  "William Carey University",
  "Itawamba Community College",
  "Blackfeet Community College",
  "Carroll College",
  "Fort Peck Community College",
  "Flathead Valley Community College",
  "Miles Community College",
  "Montana State University",
  "Montana State University Billings",
  "Montana State University – Northern",
  "Montana Tech of the University of Montana",
  "Montana University System",
  "Rocky Mountain College",
  "University of Montana Helena College",
  "University of Montana",
  "University of Montana Western",
  "Asheville-Buncombe Technical Community College",
  "Alamance Community College",
  "Appalachian State University",
  "Bladen Community College",
  "Body Therapy Institute",
  "Campbell University",
  "Cape Fear Community College",
  "Charlotte School of Law",
  "Chowan University",
  "Central Piedmont Community College",
  "Craven Community College",
  "Davidson College",
  "Duke University",
  "Durham Technical Community College",
  "Elizabeth City State University",
  "East Carolina University",
  "Elon University",
  "Fayetteville Technical Community College",
  "Forsyth Technical Community College",
  "Gardner-Webb University",
  "Gaston College",
  "Guilford Technical Community College",
  "Heritage Bible College",
  "High Point University",
  "Isothermal Community College",
  "Lenoir Community College",
  "Lees McRae College",
  "Methodist University",
  "The University of Mount Olive",
  "Montgomery Community College",
  "North Carolina Central University",
  "North Carolina School of Science and Mathematics",
  "North Carolina Agricultural and Technical State University",
  "North Carolina State University",
  "North Carolina Wesleyan College",
  "New Life Theological Seminary",
  "University of North Carolina",
  "Piedmont Community College",
  "Pitt Community College",
  "Queens University of Charlotte",
  "Randolph Community College",
  "Roanoke-Chowan Community College",
  "Salem College",
  "Sampson Community College",
  "Sandhills Community College",
  "Saint Andrews University",
  "Southeastern Community College",
  "Southeastern Baptist Theological Seminary",
  "Shaw University",
  "Southwestern Community College",
  "South Piedmont Community College",
  "Stanly Community College",
  "Saint Augustine’s University",
  "University of North Carolina at Chapel Hill",
  "University of North Carolina Asheville",
  "University of North Carolina Charlotte",
  "University of North Carolina Greensboro",
  "University of North Carolina Pembroke",
  "University of North Carolina School of the Arts",
  "University of North Carolina Wilmington",
  "Vance-Granville Community College",
  "Wake Technical Community College",
  "Warren Wilson College",
  "Western Carolina University",
  "Wake Forest University",
  "Western Piedmont Community College",
  "Winston-Salem State University",
  "Dakota College at Bottineau",
  "Mayville State University",
  "Minot State University",
  "North Dakota State University",
  "North Dakota University System",
  "Turtle Mountain Community College",
  "University of North Dakota",
  "United Tribes Technical College",
  "Valley City State University",
  "Williston State College",
  "Bellevue University",
  "Bryan College of Health Sciences",
  "Creighton University",
  "Chadron State College",
  "Concordia University Nebraska",
  "Joseph’s College",
  "Little Priest Tribal College",
  "Mid-Plains Community College",
  "University of Nebraska",
  "Northeast Community College",
  "Union College",
  "University of Nebraska at Kearney",
  "University of Nebraska at Lincoln",
  "University of Nebraska Medical Center",
  "University of Nebraska Omaha",
  "Wayne State College",
  "Saint Anselm College",
  "Antioch University New England",
  "Community College System of New Hampshire",
  "Colby Sawyer College",
  "Dartmouth College",
  "Phillips Exeter Academy",
  "Granite State College",
  "Keene State College",
  "Manchester Community College",
  "Mount Washington College",
  "Nashua Community College",
  "New England College",
  "NHTI Concord’s Community College",
  "Plymouth State University",
  "Rivier University",
  "Southern New Hampshire University",
  "University of New Hampshire",
  "White Mountains Community College",
  "Atlantic Cape Community College",
  "Burlington County College",
  "Bergen Community College",
  "Brookdale Community College",
  "Centenary College of New Jersey",
  "Drew University",
  "Eastwick College",
  "Fairleigh Dickinson University",
  "Felician College",
  "Fortis Institute",
  "Georgian Court University",
  "Kean University",
  "Mercer County Community  College",
  "Middlesex County College",
  "Monmouth University",
  "Montclair State University",
  "New Jersey City University",
  "New Jersey Institute of Technology",
  "Ocean County College",
  "Princeton University",
  "Princeton Theological Seminary",
  "Ramapo College of New Jersey",
  "Raritan Valley Community College",
  "Rider University",
  "Rowan University",
  "Rutgers University",
  "Seton Hall University",
  "Stenotech Career Institute",
  "Stevens Institute of Technology",
  "Stockton College",
  "Sussex County Community College",
  "The College of New Jersey",
  "Thomas Edison State College",
  "William Paterson University",
  "County College of Morris",
  "Camden County College",
  "Passaic County Community College",
  "Ross University",
  "Central New Mexico",
  "Eastern New Mexico University",
  "New Mexico Highlands University",
  "New Mexico State University",
  "New Mexico State University Alamogordo",
  "New Mexico Institute of Mining and Technology",
  "Northern New Mexico College",
  "San Juan College",
  "Southwestern College",
  "Univeristy of New Mexico",
  "University of the Southwest",
  "College of Southern Nevada",
  "Great Basin College",
  "Pima Medical Institute",
  "Sierra Nevada College",
  "Truckee Meadows Community College",
  "University of Nevada Las Vegas",
  "University of Nevada Reno",
  "Western Nevada College",
  "Adelphi University",
  "University at Albany",
  "Alfred University",
  "The American Musical and Dramatic Academy",
  "ASA College",
  "Bard College",
  "Barnard College",
  "Binghamton University",
  "The College at Brockport",
  "University at Buffalo",
  "Buffalo State University",
  "Cazenovia College",
  "Clarkson University",
  "Clinton Community College",
  "The College of New Rochelle",
  "Cobleskill University",
  "Colgate University",
  "Columbia University in the City of New York",
  "Concordia College New York",
  "The Cooper Union",
  "Cornell University",
  "Cortland College",
  "The City University of New York",
  "The College of Westchester",
  "Daemen College",
  "Dominican College",
  "Delhi State University of New York",
  "Dowling College",
  "D’Youville College",
  "Erie Community College State University of New York",
  "Elim Bible Institute",
  "Elmira College",
  "Empire State College",
  "College of Environmental Science and Forestry",
  "Farmingdale State College",
  "Fashion Institute of Technology",
  "Finger Lakes Community College",
  "Fordham University",
  "Fredonia University",
  "Five Towns College",
  "Geneseo University",
  "Globe Institute of Technology",
  "The General Theological Seminary",
  "Hamilton College",
  "Hartwick College",
  "Herkimer College",
  "Hofstra University",
  "Hudson Valley Community College",
  "Hobart and William Smith Colleges",
  "Institute of Design and Construction",
  "Iona College",
  "Ithaca College",
  "The Jewish Theological Seminary",
  "The Juilliard School",
  "Keuka College",
  "Lehman College",
  "Le Moyne",
  "Lim College",
  "Long Island University",
  "Manhattan College",
  "Marist College",
  "Metropolitan College of New York",
  "Mercy College",
  "Marymount Manhattan College",
  "Monroe Community College",
  "Morrisville State College",
  "Mount Saint Marry College",
  "Icahn School of Medicine at Mount Sinai",
  "Manhattanville College",
  "Nazareth College",
  "Nassau Community College",
  "New Paltz",
  "The New School",
  "Niagara University",
  "Nyack College",
  "New York College of Health Professions",
  "New York Film Academy",
  "New York Institute of Technology",
  "New York University",
  "State University of New York at Oneonta",
  "State University of New York at Oswego",
  "Pace University",
  "Paul Smith’s College",
  "State University of New York at Plattsburgh",
  "State University of New York at Potsdam",
  "Pratt Institute",
  "Purchase College",
  "Rochester Institute of Technology",
  "Roberts Wesleyan College",
  "University of Rochester",
  "Rensselaer Polytechnic Institute",
  "The Sages Colleges",
  "Saint Bonaventure University",
  "The New York Conservatory for Dramatic Arts",
  "Siena College",
  "Saint John Fisher College",
  "Skidmore College",
  "Sarah Lawrence College",
  "Saint Bernard’s School of Theology and Ministry",
  "Saint John’s University",
  "Saint Lawrence University",
  "Stony Brook University",
  "Stony Brook Medicine",
  "The College of Saint Rose",
  "State University of New York",
  "State University of New York at Adirondack",
  "State University of New Institute of Technology",
  "Jamestown Community College",
  "Orange County Community College",
  "Rockland Community College",
  "Suffolk County Community College",
  "Westchester Community College",
  "Syracuse University",
  "Tompkins Cortland Community College",
  "The Touro College and University System",
  "Touro College Jacob D. Fuchsberg Law Center",
  "Union College",
  "Upstate  Medical University",
  "United States Military Academy West Point",
  "Utica College",
  "Vassar College",
  "Wagner College",
  "Wells College",
  "Yeshiva University",
  "Canton State University of New York",
  "Excelsior College",
  "Culinary Institute of America",
  "Ashland University",
  "Aultman College of Nursing and Health Sciences",
  "Belmont College",
  "Bowling Green State University",
  "Baldwin Wallace University",
  "Capital University",
  "Case Western Reserve University",
  "Cincinnati College of Mortuary Science",
  "Cedarville University",
  "Central State University",
  "Cincinnati State Technical and Community College",
  "Central Ohio Technical College",
  "Columbus State Community College",
  "Cleveland State University",
  "Western Reserve University",
  "Defiance College",
  "Denison University",
  "The University of Findlay",
  "Franklin University",
  "Heidelberg University",
  "Hiram College",
  "Hocking College",
  "John Carroll University",
  "Kettering College",
  "Kent State University",
  "Kenyon College",
  "Lakeland Community College",
  "Lima Central Catholic High School",
  "Lorain County Community College",
  "Malone University",
  "Marietta College",
  "Miami University",
  "University of Mount Union",
  "Mount Saint Joseph University",
  "Muskingum University",
  "Mount Vernon Nazarene University",
  "North Central State College",
  "Oberlin College and Conservatory",
  "Ohio University",
  "Ohio Dominican University",
  "Ohio State University",
  "Ohio Northern University",
  "Otterbein University",
  "Owens Community College",
  "Ohio Wesleyan University",
  "University of Rio  Grande",
  "Shawnee State University",
  "Sinclair Community College",
  "Tiffin University",
  "Cuyahoga Community College",
  "The University of Akron",
  "University of Cincinnati",
  "University of Cincinnati Blue Ash",
  "University of Cincinnati Clermont",
  "University of Dayton",
  "Urbana University",
  "Ursuline College",
  "University of Toledo",
  "Walsh University",
  "Wilmington College",
  "Wittenberg University",
  "The College of Wooster",
  "Wright State University",
  "Xavier University",
  "Youngstown State University",
  "Ohio State University",
  "Cameron University",
  "Connors State College",
  "East  Central University",
  "Langston University",
  "Mid-America Christian University",
  "Meridian Technology Center",
  "Northeastern State University",
  "Northwestern Oklahoma State University",
  "Oklahoma Christian University",
  "Oklahoma City Community College",
  "Oklahoma City University",
  "Oklahoma State University",
  "Oral Roberts University",
  "Oklahoma State University Institue of Technology",
  "Oklahoma State University",
  "The University of Oklahoma",
  "The University of Oklahoma Health Sciences Center",
  "Rose State College",
  "Rogers State University",
  "Southeastern Oklahoma State University",
  "Southwestern Christian University",
  "Southwestern Oklahoma State Univeristy",
  "Tulsa Community College",
  "University of Central Oklahoma",
  "University of Science and Arts of Oklahoma",
  "University of Tulsa",
  "Western Oklahoma State College",
  "Blue Mountain Community College",
  "Clatsop Community College",
  "Central Oregon Community College",
  "Columbia School of English",
  "Concordia University Portland Oregon",
  "Eastern Oregon University",
  "George Fox University",
  "Lane Community College",
  "Lewis and Clark",
  "Linfield College",
  "Linn-Benton Community College",
  "Marylhurst University",
  "National College of Natural Medicine",
  "Oregon Health and Science University",
  "Oregon State University",
  "Pacific University Oregon",
  "Portland Community College",
  "Portland State University",
  "Reed College",
  "Rogue Community College",
  "Southwestern Oregon Community  College",
  "Southern Oregon University",
  "University of Oregon",
  "University of Portland",
  "Warner Pacific College",
  "Willamette University",
  "Western Oregon University",
  "Allegheny College",
  "Alvernia University",
  "Arcadia University",
  "Baptist Bible College & Seminary",
  "Butler County Community College",
  "Bloomsburg University",
  "Bryn Mawr College",
  "Bucknell University",
  "Bucks County Community College",
  "California University of Pennsylvania",
  "Carlow University",
  "Community College of Allegheny County",
  "Community College of Philadelphia",
  "Cedar Crest College",
  "Chatham University",
  "Chestnut Hill College",
  "Clarion University",
  "Carnegie Mellon University",
  "Delaware County Community College",
  "Delaware Valley College",
  "Dickinson College",
  "Drexel University",
  "Drexel University College of Medicine",
  "Duquesne University",
  "Eastern University",
  "Edinboro University",
  "Elizabethtown College",
  "Evangelical Seminary",
  "Franklin & Marshall College",
  "Saint Francis University",
  "Grove City College",
  "Geneva College",
  "Gettysburg College",
  "Harrisburg Area Community College",
  "Haverford College",
  "Holy Family University",
  "Indiana University of Pennsylvania",
  "Thomas Jefferson University",
  "Juniata College",
  "Keystone College",
  "King’s College",
  "Kutztown University",
  "Lackawanna College",
  "Lafayette College",
  "Lancaster County Career and Technology Center",
  "Lancaster Theological Seminary",
  "La Roche College",
  "La Salle University",
  "Lehigh Carbon Community College",
  "Lake Erie College of Osteopathic Medicine",
  "Lehigh University",
  "Lock Haven University",
  "The Lincoln University",
  "Lansdale School of Business",
  "Lebanon Valley College",
  "Lycoming College",
  "Manor College",
  "Mansfield University",
  "Marywood University",
  "Montgomery County Community College",
  "Mercyhurst University",
  "Messiah College",
  "Millersville University",
  "Misericordia University",
  "Moravian College",
  "Mount Aloysius College",
  "Muhlenberg College",
  "Neumann University",
  "Northampton Community College",
  "Pennsylvania Gunsmith School",
  "Philadelphia College of Osteopathic Medicine",
  "Pennsylvania College of Technology",
  "Philadelphia University",
  "University of Pittsburgh",
  "Penn State University",
  "Reading Area Community College",
  "Robert Morris University",
  "Saint Vincent Seminary",
  "Salus University",
  "The University of Scranton",
  "Shippensburg University",
  "Saint Joseph’s University",
  "Slippery Rock University",
  "Saint Vincent College",
  "Susquehanna University",
  "Swarthmore College",
  "Temple University",
  "The  American College of Financial Services",
  "Thiel College",
  "University of the Arts",
  "University of Pennsylvania",
  "University of the Sciences in Philadelphia",
  "Valley Forge Christian College",
  "Villanova University",
  "Washington and Jefferson  College",
  "Westmoreland County Community College",
  "West  Chester University",
  "Westminster College",
  "Widener University",
  "Wilkes University",
  "Wilson College",
  "York College of Pennsylvania",
  "Pennsylvania Academy of the Fine Arts",
  "East Stroudsburg University",
  "Ursinus College",
  "DeSales University",
  "Lancaster Bible College",
  "Cheyney University of Pennsylvania",
  "Brown University",
  "Bryant University",
  "Community College of Rhode Island",
  "Johnson and Wales University",
  "New England Institute of Technology",
  "Providence College",
  "The University of Rhode Island",
  "Rhode Island College",
  "Rhose Island School of Design",
  "Roger Williams University",
  "University of Rhode Island",
  "Centura College",
  "The Citadel Military College of South Carolina",
  "Columbia International University",
  "Clemson University",
  "Coastal Carolina University",
  "College of Charleston",
  "Coker College",
  "Columbia College South Carolina",
  "Florence-Darlington Technical College",
  "Francis Marion University",
  "Forrest College",
  "Furman University",
  "Lander University",
  "Limestone College",
  "Midlands Technical College",
  "Medical University of South Carolina",
  "Newberry College",
  "North Greenville University",
  "Orangeburg-Calhoun Technical College",
  "Presbyterian College",
  "University of South Carolina",
  "South Carolina State University",
  "Sinte Gleska University",
  "Spartanburg Methodist College",
  "TriCounty Technical College",
  "University of South Carolina Aiken",
  "University of South Carolina Beaufort",
  "University of South Carolina Upstate",
  "Winthrop University",
  "Wofford College",
  "York Technical College",
  "Bob Jones University",
  "Anderson University",
  "Black Hills State University",
  "Dakota State University",
  "Dakota Wesleyan University",
  "Mount Marty College",
  "Northern State University",
  "South Dakota School of Mines and Technology",
  "South Dakota State University",
  "University of South Dakota",
  "University of Sioux Falls",
  "Western Dakota Technical Institute",
  "Austin Peay State University",
  "Belmont University",
  "Bryan College",
  "Christian Brothers University",
  "Carson-Newman University",
  "Columbia State Community College",
  "Cumberland University",
  "DeVry University",
  "East Tennessee State University",
  "Freed-Hardeman University",
  "Fisk University",
  "Huntington College of Health Sciences",
  "Hiwassee College",
  "Johnson University",
  "Jackson State Community College",
  "Lee University",
  "Lincoln Memorial University",
  "The LeMoyne-Owen College",
  "The University of Memphis",
  "Milligan College",
  "Middle Tennessee State University",
  "Nashville State Community College",
  "Pellissippi State Community College",
  "Rhodes College",
  "Roane State Community College",
  "Southern College of Optometry",
  "Sewanee The University of the South",
  "Southern Adventist University",
  "The University of Tennessee",
  "Southwest Tennessee Community College",
  "Tennessee State University",
  "Tennessee Technological University",
  "Tennessee Wesleyan College",
  "University of Tennessee Chattanooga",
  "University of Tennessee Health Science Center",
  "University of Tennessee Knoxville",
  "University of Tennessee Martin",
  "University of Tennessee Space Institute",
  "Union University",
  "Vanderbilt University",
  "Volunteer State Community College",
  "King University",
  "Abilene Christian University",
  "Alamo Colleges",
  "Alvin Community College",
  "Angelo State University",
  "Austin Community College District",
  "Austin College",
  "Austin Graduate School of Theology",
  "Baylor University",
  "Blinn College",
  "Brazosport College",
  "Brookhaven College",
  "Baptist Health System School of Health Professions",
  "Career Point College",
  "Cedar Valley College",
  "Coastal Bend College",
  "College of the Mainland",
  "Concordia University Texas",
  "Central Texas College",
  "Culinary Institute LeNotre",
  "Dallas Baptist University",
  "Del Mar College",
  "Dallas Nursing Institute",
  "El Paso Community College",
  "East Texas Baptist University",
  "Galen College of Nursing",
  "Galveston College",
  "Grayson College",
  "Houston Baptist University",
  "Houston Community College",
  "Hill College",
  "Howard College",
  "Howard Payne University",
  "Lamar University",
  "Lamar State College – Port Arthur",
  "Lubbock Christian University",
  "LeTourneau University",
  "Lamar Institute of Technology",
  "Lone Star College System",
  "Lamar State College – Orange",
  "McLennan Community College",
  "McMurry University",
  "Midland College",
  "Midwestern  State University",
  "National American University",
  "North Central Texas College",
  "North American University",
  "North Lake College",
  "Odessa College",
  "Ogle School",
  "Our Lady of the Lake University",
  "Panola College",
  "Patty Hands Shelton School of Nursing",
  "Prairie View A&M University",
  "Rice University",
  "Richland College",
  "San Jacinto College",
  "Schreiner University",
  "Stephen F. Austin State University",
  "Sam Houston State University",
  "Southern Methodist University",
  "South Plains College",
  "South Texas College",
  "Southwestern University",
  "Seminary of the Southwest",
  "South Texas College of Law",
  "Saint Edward’s University",
  "Saint Mary’s University",
  "University of Saint Thomas",
  "Texas A&M Health Science Center",
  "Texas A&M International University",
  "Texas A&M University",
  "Texas A&M University Commerce",
  "Texas A&M University Corpus Christi",
  "Texas A&M University Kingsville",
  "Texas A&M University Texarkana",
  "Tarleton State University",
  "Texas Christian University",
  "Temple College",
  "Tyler Junior College",
  "Trinity University",
  "Texas State Technical College",
  "Texas Southern University",
  "Texas Tech University",
  "Texas Tech University Health Sciences Center",
  "Texas Woman’s University",
  "Texas Chiropractic College",
  "Texas State University",
  "University of Dallas",
  "Universty of Houston",
  "University of Houston Clear Lake",
  "University of Houston Downtown",
  "University of the Incarnate Word",
  "University of Mary Hardin-Baylor",
  "University of North Texas",
  "University of North Texas Health Science Center",
  "University of Texas Arlington",
  "University of Texas Brownsville",
  "University of Texas Dallas",
  "University of Texas at El Paso",
  "University of Texas at Austin",
  "University of Texas Health Science Center Houston",
  "University of Texas Health Science Center San Antonio",
  "University of Texas Medical Branch at Galveston",
  "University of Texas Pan American",
  "University of Texas of the Permian Basin",
  "University of Texas at San Antonio",
  "University of Texas Southwestern Medical Center",
  "University of Texas at Tyler",
  "Valley Grande Institute",
  "Victoria College",
  "Wayland Baptist University",
  "Weatherford College",
  "West Texas A&M University",
  "University of the Virgin Islands",
  "Brigham Young University",
  "Dixie State University",
  "Salt Lake Community College",
  "Snow College",
  "Southern Utah University",
  "Utah State University",
  "University of Utah",
  "Utah Valley University",
  "Weber State University",
  "Averett University",
  "Christendom College",
  "Christopher Newport University",
  "Dabney S. Lancaster Community College",
  "Eastern Mennonite University",
  "Eastern Virginia Medical School",
  "Ferrum College",
  "Germanna Community College",
  "George Mason University",
  "Hampden-Sydney College",
  "The Institute for the Psychological Sciences",
  "James Madison University",
  "Liberty University",
  "Longwood University",
  "Marymount University",
  "New River Community College",
  "Norfolk State University",
  "Northern Virginia Community College",
  "Old Dominion University",
  "Radford University",
  "Randolph College",
  "Rappahannock Community College",
  "Regent University",
  "Reynolds Community College",
  "University of Richmond",
  "Randolph-Macon College",
  "Roanoke College",
  "Sentara College of Health Sciences",
  "Stratford University",
  "Shenandoah University",
  "Southern Virgina University",
  "Southwest Virgina Community College",
  "Tidewater Community College",
  "Thomas Nelson Community College",
  "University of Mary Washington",
  "University of Virginia’s College at Wise",
  "Virginia’s Community Colleges",
  "Virginia Commonwealth University",
  "Virgina Highlands Community College",
  "Virginia Institute of Marine Science",
  "University of Virginia",
  "Virginia Western Community College",
  "Virginia International University",
  "Virginia State University",
  "Virginia Tech",
  "Virginia Union University",
  "Virginia Wesleyan College",
  "Washington and Lee University",
  "The College of William and Mary",
  "Castleton State College of Vermont",
  "Champlain College",
  "Green Mountain College",
  "Johnson State College",
  "Middlebury College",
  "New England Culinary Institute",
  "Norwich  University",
  "School for International Training",
  "Saint Michael’s College",
  "University of Vermont",
  "University of Vermont",
  "Vermont Law School",
  "Vermont Technical College",
  "Bennington College",
  "Bellevue College",
  "Big Bend Community College",
  "Cascadia Community College",
  "Centralia College",
  "Charter College",
  "Clark College",
  "Central Washington University",
  "DigiPen Institute of Technology",
  "Edmonds Community College",
  "Everett Community College",
  "The Evergreen State College",
  "Eastern Washington University",
  "Faith Evangelical College and Seminary",
  "Gonzaga University",
  "Green River Community College",
  "Highline College",
  "Mukogawa Fort Wright Institute",
  "North Seattle College",
  "Olympic College",
  "Peninsula College",
  "Perry Technical Institute",
  "Pacific Lutheran University",
  "Renton Technical College",
  "Seattle Central College",
  "Seattle University",
  "Shoreline Community College",
  "Seattle Institute of Oriental Medicine",
  "Skagit Valley College",
  "South Seattle College",
  "Spokane Falls Community College",
  "Seattle Pacific University",
  "Saint Martin’s University",
  "University of Puget Sound",
  "University of Washington",
  "University of Washington Bothell",
  "Walla Walla University",
  "Whitman College",
  "Whitworth University",
  "Washington State University",
  "Wenatchee Valley College",
  "Walla Walla Community College",
  "Western Washington University",
  "Yakima Valley Community College",
  "University of Washington",
  "Alverno College",
  "Carroll University",
  "Carthage College",
  "Chippewa Valley Technical College",
  "Edgewood College",
  "Gateway Technical College",
  "The Institute of Beauty and Wellness",
  "Lawrence University",
  "Madison Area Technical College",
  "Marquette University",
  "Milwaukee Area Technical College",
  "Medical College of Wisconsin",
  "Milwaukee Institute of Art and Design",
  "Moraine Park Technical College",
  "Milwaukee School of Engineering",
  "Northland College",
  "Northcentral  Technical College",
  "Northeast Wisconsin Technical College",
  "Silver Lake College of the Holy Family",
  "Saint Norbert College",
  "Cardinal Stritch University",
  "University of Wisconsin Colleges",
  "University of Wisconsin Eau Claire",
  "University of Wisconsin Extension",
  "University of Wisconsin Green Bay",
  "University of Wisconsin La Crosse",
  "University of Wisconsin Milwaukee",
  "University of Wisconsin Oshkosh",
  "University of Wisconsin Platteville",
  "University of Wisconsin River Falls",
  "University of Wisconsin Stevens Point",
  "University of Wisconsin Superior",
  "University of Wisconsin Whitewater",
  "Viterbo University",
  "Waukesha County Technical College",
  "University of Wisconsin, Madison",
  "Wisconsin Indianhead Technical College",
  "Wisconsin Lutheran College",
  "Wisconsin Technical College System",
  "Western Technical College",
  "American Public University System",
  "Concord University",
  "Eastern West Virgina Community and Technical College",
  "Fairmont State University",
  "Marshall University",
  "Ohio Valley University",
  "Pierpont Community and Technical College",
  "Shepherd University",
  "University of Charleston",
  "West Liberty University",
  "Wheeling Jesuit University",
  "West Virginia Northern Community College",
  "West Virginia State University",
  "West Virginia University",
  "West Virginia Wesleyan College",
  "Casper College",
  "Central Wyoming College",
  "National Outdoor Leadership School",
  "Northwest College",
  "Northern Wyoming Community College District",
  "University of Wyoming",
  "Alabama State University",
  "Bishop State Community College",
  "J. F. Drake State Community and Technical College",
  "Lawson State Community College",
  "Shelton State Community College",
  "Stillman College",
  "Talladega College",
  "Arkansas Baptist College",
  "Philander Smith College",
  "Shorter College",
  "Edward Waters College",
  "Florida Agricultural and Mechanical University",
  "Florida Memorial University",
  "Albany State University",
  "Clark Atlanta University",
  "Fort Valley State University",
  "Simmons College of Kentucky",
  "Dillard University",
  "Grambling State University",
  "Southern University and A & M College",
  "Southern University at New Orleans",
  "Southern University at Shreveport",
  "Morgan State University",
  "University of Maryland Eastern Shore",
  "Coahoma Community College",
  "Mississippi Valley State University",
  "Rust College",
  "Tougaloo College",
  "Bennett College",
  "Fayetteville State University",
  "Johnson C Smith University",
  "Livingstone College",
  "Wilberforce University",
  "Allen University",
  "Benedict College",
  "Claflin University",
  "Clinton College",
  "Denmark Technical College",
  "Morris College",
  "Voorhees College",
  "American Baptist College",
  "Lane College",
  "Le Moyne-Owen College",
  "Meharry Medical College",
  "Huston-Tillotson University",
  "Jarvis Christian University",
  "Paul Quinn College",
  "Prairie View A & M University",
  "Southwestern Christian College",
  "St Philip’s College",
  "Texas College",
  "Wiley College",
  "Hampton University",
  "University of the Virgin Islands-Albert A. Sheen",
  "Virginia University of Lynchburg",
  "Bluefield State College",
  "Arizona Christian University",
  "Chandler-Gilbert Community College",
  "Cochise County Community College District",
  "Estrella Mountain Community College",
  "GateWay Community College",
  "Mohave Community College",
  "Ottawa University, Phoenix",
  "Ottawa University, Surprise",
  "Paradise Valley Community College",
  "Cossatot Community College of the University of Arkansas",
  "Ecclesia College",
  "American River College",
  "Antelope Valley Community College District",
  "Antioch University, Santa Barbara",
  "Azusa Pacific University",
  "California Christian College",
  "California State Polytechnic University, Humboldt",
  "California State University, Bakersfield",
  "California State University, Channel Islands",
  "California State University, Chico",
  "California State University, Fresno",
  "California State University, Los Angeles",
  "Casa Loma College, Van Nuys",
  "Clovis Community College",
  "Coastline Community College",
  "College of Alameda",
  "Compton College",
  "Concordia University, Irvine",
  "Cosumnes River College",
  "El Camino Community College District",
  "Evergreen Valley College",
  "Hope International University",
  "Humphreys University, Stockton and Modesto",
  "John Paul the Great Catholic University",
  "Lassen Community College",
  "Life Pacific University",
  "Los Angeles Pacific University",
  "Los Angeles Pierce College",
  "Mendocino College",
  "Merced College",
  "Merritt College",
  "Mission College",
  "Monterey Peninsula College",
  "Moorpark College",
  "Moreno Valley College",
  "Mount Saint Mary's University",
  "Mount San Jacinto Community College District",
  "National University",
  "Oxnard College",
  "Pacific Oaks College",
  "Pacific Union College",
  "Palo Alto University",
  "Palo Verde College",
  "Providence Christian College",
  "Reedley College",
  "Sacramento City College",
  "Saint Mary's College of California",
  "San Diego Christian College",
  "San Diego Miramar College",
  "San Jose City College",
  "Santiago Canyon College",
  "University of Saint Katherine",
  "University of the West",
  "Vanguard University of Southern California",
  "West Hills College, Coalinga",
  "West Hills College, Lemoore",
  "West Los Angeles College",
  "Woodbury University",
  "Woodland Community College",
  "World Mission University",
  "Colorado Mountain College",
  "Lamar Community College",
  "Morgan Community College",
  "Otero Junior College",
  "Trinidad State College",
  "Trinity Washington University",
  "Capital Community College",
  "Housatonic Community College",
  "University of Bridgeport",
  "University of Connecticut, Stamford",
  "University of Connecticut, Waterbury",
  "Dalton State College",
  "AdventHealth University",
  "Albizu University, Miami",
  "Altierus Career College, Tampa",
  "Ana G. Mendez University",
  "City College Miami",
  "College of the Florida Keys",
  "Florida SouthWestern State College",
  "Hodges University",
  "Indian River State College",
  "Johnson University Florida",
  "Polk State College",
  "Polytechnic University of Puerto Rico, Miami",
  "Polytechnic University of Puerto Rico, Orlando",
  "Saint Thomas University",
  "Seminole State College of Florida",
  "Trinity International University, Florida",
  "Chicago School of Professional Psychology at Chicago",
  "City Colleges of Chicago, Harold Washington College",
  "City Colleges of Chicago, Harry S Truman College",
  "City Colleges of Chicago, Malcolm X College",
  "City Colleges of Chicago, Olive-Harvey College",
  "City Colleges of Chicago, Richard J. Daley College",
  "City Colleges of Chicago, Wilbur Wright College",
  "Concordia University, Chicago",
  "Dominican University, River Forest, Illinois",
  "Elmhurst University",
  "Oak Point University",
  "Saint Augustine College",
  "William Rainey Harper College",
  "Calumet College of Saint Joseph",
  "Seward County Community College",
  "Benjamin Franklin Institute of Technology",
  "Bunker Hill Community College",
  "Holyoke Community College",
  "North Shore Community College",
  "Northern Essex Community College",
  "Urban College of Boston",
  "Nevada State College",
  "Bloomfield College",
  "Caldwell University",
  "Essex County College",
  "Hudson County Community College",
  "Pillar College",
  "Saint Elizabeth University",
  "Saint Peter's University",
  "Union County College",
  "William Paterson University of New Jersey",
  "Central New Mexico Community College",
  "Eastern New Mexico University, Ruidoso Branch Community College",
  "Luna Community College",
  "New Mexico State University, Dona Ana",
  "New Mexico State University, Grants",
  "New Mexico State University, Main Campus",
  "Santa Fe Community College",
  "Southeast New Mexico College",
  "Western New Mexico University",
  "University of New Mexico, Valencia County",
  "University of New Mexico, Taos",
  "University of New Mexico, Los Alamos",
  "University of New Mexico, Gallup",
  "Boricua College",
  "College of Mount Saint Vincent",
  "CUNY, Bernard M. Baruch College",
  "CUNY, Bronx Community College",
  "CUNY, City College",
  "CUNY, College of Staten Island",
  "CUNY, Hostos Community College",
  "CUNY, Hunter College",
  "CUNY, John Jay College of Criminal Justice",
  "CUNY, LaGuardia Community College",
  "CUNY, Lehman College",
  "CUNY, New York City College of Technology",
  "CUNY, Queens College",
  "CUNY, Queensborough Community College",
  "CUNY, Stella and Charles Guttman Community College",
  "CUNY, York College",
  "Dominican College of Blauvelt",
  "Saint Francis College",
  "SUNY, College at Old Westbury",
  "SUNY, Purchase College",
  "Vaughn College of Aeronautics and Technology",
  "James Sprunt Community College",
  "Union Institute & University",
  "Family of Faith Christian University",
  "Oklahoma Panhandle State University",
  "Chemeketa Community College",
  "New Hope Christian College, Eugene",
  "Treasure Valley Community College",
  "Warner Pacific University",
  "Northampton County Area Community College",
  "Reading Area Community College",
  "College Unbound",
  "Altierus Career College, Bissonnet",
  "Amarillo College",
  "Angelina College",
  "Baptist University of the Americas",
  "Cisco College",
  "Dallas College",
  "Frank Phillips College",
  "Hallmark University",
  "Laredo College",
  "Lee College",
  "Northeast Lakeview College",
  "Northeast Texas Community College",
  "Northwest Vista College",
  "Palo Alto College",
  "Ranger College",
  "Remington College, Dallas",
  "Remington College, Houston Southeast",
  "Remington College, North Houston",
  "Saint Edward's University",
  "Saint Mary's University",
  "San Antonio College",
  "San Jacinto Community College",
  "Southwest Texas Junior College",
  "Southwestern Adventist University",
  "Sul Ross State University",
  "Tarrant County College District",
  "Texas A & M International University",
  "Texas A & M University, Central Texas",
  "Texas A & M University, College Station",
  "Texas A & M University, Corpus Christi",
  "Texas A & M University, Kingsville",
  "Texas A & M University, San Antonio",
  "Texas Lutheran University",
  "Texas Southmost College",
  "Texas Wesleyan University",
  "Texas Woman's University",
  "Trinity Valley Community College",
  "University of Houston, Victoria",
  "University of Texas at Arlington",
  "University of Texas, Rio Grande Valley",
  "Vernon College",
  "Western Texas College",
  "Wharton County Junior College",
  "Columbia Basin College",
  "Gather 4 Him Christian College",
  "Heritage University",
  "Yakima Valley College",
  "Herzing University, Kenosha",
  "Mount Mary University",
  "Albizu University, San Juan",
  "American University of Puerto Rico, Bayamon",
  "American University of Puerto Rico, Manati",
  "Atenas College",
  "Atlantic University College",
  "Caribbean University, Bayamon",
  "Caribbean University, Carolina",
  "Caribbean University, Ponce",
  "Caribbean University, Vega Baja",
  "Colegio Universitario de San Juan",
  "Conservatory of Music of Puerto Rico",
  "Dewey University, Carolina",
  "Dewey University, Hato Rey",
  "Dewey University, Juana DÃ­az",
  "Dewey University, Manati",
  "EDP University of Puerto Rico, Humacao",
  "EDP University of Puerto Rico, Manati",
  "EDP University of Puerto Rico, San Juan",
  "EDP University of Puerto Rico, San Sebastian",
  "EDP University of Puerto Rico, Villalba",
  "Escuela de Artes Plasticas y Diseno de Puerto Rico",
  "Humacao Community College",
  "Instituto Tecnologico de Puerto Rico, Recinto de Manati",
  "Instituto Tecnologico de Puerto Rico, Recinto de Ponce",
  "Instituto Tecnologico de Puerto Rico, Recinto de San Juan",
  "Inter American University of Puerto Rico, Aguadilla",
  "Inter American University of Puerto Rico, Arecibo",
  "Inter American University of Puerto Rico, Barranquitas",
  "Inter American University of Puerto Rico, Bayamon",
  "Inter American University of Puerto Rico, Fajardo",
  "Inter American University of Puerto Rico, Guayama",
  "Inter American University of Puerto Rico, Metro",
  "Inter American University of Puerto Rico, Ponce",
  "Inter American University of Puerto Rico, San German",
  "Pontifical Catholic University of Puerto Rico, Arecibo",
  "Pontifical Catholic University of Puerto Rico, Mayaguez",
  "Pontifical Catholic University of Puerto Rico, Ponce",
  "San Juan Bautista School of Medicine",
  "Universal Technology College of Puerto Rico",
  "Universidad Adventista de las Antillas",
  "Universidad Ana G. Mendez, Carolina",
  "Universidad Ana G. Mendez, Cupey",
  "Universidad Ana G. Mendez, Gurabo",
  "Universidad Central de Bayamon",
  "Universidad Central Del Caribe",
  "Universidad del Sagrado Corazon",
  "Universidad Pentecostal Mizpa",
  "Universidad Politecnica de Puerto Rico",
  "Universidad Teologica del Caribe",
  "University of Puerto Rico, Aguadilla",
  "University of Puerto Rico, Arecibo",
  "University of Puerto Rico, Bayamon",
  "University of Puerto Rico, Carolina",
  "University of Puerto Rico, Cayey",
  "University of Puerto Rico, Humacao",
  "University of Puerto Rico, Mayaguez",
  "University of Puerto Rico, Medical Sciences",
  "University of Puerto Rico, Ponce",
  "University of Puerto Rico, Rio Piedras",
  "University of Puerto Rico, Utuado",
];
export const yearsOfExpOptions = [
  "1 - 5 years",
  "6+ years",
  "11+ years",
  "15+ years",
];
export const ageRangeOptions = [
  "18 – 25 years",
  "26+ years",
  "31+ years",
  "41+ years",
  "51+ years",
];
export const academicLevelOptions = [
  "First Year",
  "Sophomore",
  "Junior",
  "Senior",
  "Graduate Students (Masters and Ph.D.)",
];
export const levelEducationOptions = [
  "None",
  "Diploma",
  "Bachelor’s degree",
  "Master’s degree",
  "Ph.D.",
];

export const pronounsOptions = [
  { label: "She/Her", value: "She/Her" },
  { label: "He/Him", value: "He/Him" },
  { label: "They/Them", value: "They/Them" },
  { label: "Prefer not to say", value: "Prefer not to say" },
  { label: "Others please specify", value: "Others" },
];

export const profLicenseOptions = [
  { label: "None", value: "None" },
  {
    label: "Associated Risk Manager (ARM)",
    value: "Associated Risk Manager (ARM)",
  },
  {
    label: "Certified Associate Construction Manager (CACM)",
    value: "Certified Associate Construction Manager (CACM)",
  },
  {
    label: "Certified Associate in Project Management (CAPM)",
    value: "Certified Associate in Project Management (CAPM)",
  },
  {
    label: "Certified Construction Manager (CCM)",
    value: "Certified Construction Manager (CCM)",
  },
  {
    label: "Certified/Associate Safety Professional (CSP/ASP)",
    value: "Certified/Associate Safety Professional (CSP/ASP)",
  },
  {
    label: "Certified Healthcare Environmental Services Technician (CHEST)",
    value: "Certified Healthcare Environmental Services Technician (CHEST)",
  },
  {
    label: "Certified Health and Safety Technician (CHST)",
    value: "Certified Health and Safety Technician (CHST)",
  },
  { label: "General Contractor", value: "General Contractor" },
  {
    label: "LEED Accredited Professional (LEED AP)",
    value: "LEED Accredited Professional (LEED AP)",
  },
  {
    label: "LEED Green Associate",
    value: "LEED Green Associate",
  },
  { label: "Licensed Architect (AIA)", value: "Licensed Architect (AIA)" },
  { label: "OSHA 10", value: "OSHA 10" },
  { label: "OSHA 30", value: "OSHA 30" },
  {
    label: "Principles and Practice of Engineering (PE)",
    value: "Principles and Practice of Engineering (PE)",
  },
  { label: "Professional Engineer (PE)", value: "Professional Engineer (PE)" },
  { label: "Professional Builder (PB)", value: "Professional Builder (PB)" },
  {
    label: "Site Safety Training (SST) Certification",
    value: "Site Safety Training (SST) Certification",
  },
  {
    label: "Tradesman license",
    value: "Tradesman license",
  },
];
export const typeProjectOptions = [
  { label: "Amusement and Recreation", value: "Amusement and Recreation" },
  { label: "Commercial Construction", value: "Commercial Construction" },
  { label: "Communication", value: "Communication" },
  {
    label: "Conservation and Development",
    value: "Conservation and Development",
  },
  { label: "Education", value: "Education" },
  { label: "Geotechnical and Tunneling", value: "Geotechnical and Tunneling" },
  { label: "Healthcare", value: "Healthcare" },
  { label: "Highway and Street", value: "Highway and Street" },
  { label: "Lodging", value: "Lodging" },
  { label: "Manufacturing", value: "Manufacturing" },
  {
    label: "Mechanical, Electrical, and Plumbing",
    value: "Mechanical, Electrical, and Plumbing",
  },
  { label: "Offices", value: "Offices" },
  { label: "Oil and Gas Construction", value: "Oil and Gas Construction" },
  { label: "Power", value: "Power" },
  { label: "Public Safety", value: "Public Safety" },
  { label: "Religious Construction", value: "Religious Construction" },
  { label: "Residential Construction", value: "Residential Construction" },
  { label: "Sewage and Waste Disposal", value: "Sewage and Waste Disposal" },
  { label: "Special Trade Construction", value: "Special Trade Construction" },
  {
    label: "Transportation/Highway Construction",
    value: "Transportation/Highway Construction",
  },
  { label: "Water Supply", value: "Water Supply" },
];
export const deptOptions = [
  {
    label: "Architectural Construction Management",
    value: "Architectural Construction Management",
  },
  { label: "Architectural Engineering", value: "Architectural Engineering" },
  {
    label: "Architectural Engineering Technology",
    value: "Architectural Engineering Technology",
  },
  { label: "Architectural Science", value: "Architectural Science" },
  { label: "Architectural Studies", value: "Architectural Studies" },
  { label: "Architecture", value: "Architecture" },
  {
    label: "Architecture and Environmental Design",
    value: "Architecture and Environmental Design",
  },
  {
    label: "Building and Construction Technology",
    value: "Building and Construction Technology",
  },
  { label: "Building Construction", value: "Building Construction" },
  { label: "Built Environment", value: "Built Environment" },
  {
    label: "Civil and Environmental Engineering",
    value: "Civil and Environmental Engineering",
  },
  { label: "Civil Engineering", value: "Civil Engineering" },
  {
    label: "Civil Engineering Technology",
    value: "Civil Engineering Technology",
  },
  { label: "Construction Engineering", value: "Construction Engineering" },
  {
    label: "Construction Engineering and Management",
    value: "Construction Engineering and Management",
  },
  {
    label: "Construction Engineering Technology",
    value: "Construction Engineering Technology",
  },
  { label: "Construction Management", value: "Construction Management" },
  {
    label: "Construction Management Engineering Technology",
    value: "Construction Management Engineering Technology",
  },
  {
    label: "Construction Management Technology",
    value: "Construction Management Technology",
  },
  { label: "Construction Science", value: "Construction Science" },
  {
    label: "Construction Science and Management",
    value: "Construction Science and Management",
  },
  { label: "Construction Technology", value: "Construction Technology" },
  { label: "Environmental Engineering", value: "Environmental Engineering" },
  {
    label: "Environmental Health and Safety",
    value: "Environmental Health and Safety",
  },
  { label: "Geomatics", value: "Geomatics" },
  { label: "Landscape Architecture", value: "Landscape Architecture" },
  {
    label: "Mechanical Engineering",
    value: "Mechanical Engineering",
  },
  {
    label: "Real Estate and Construction Management",
    value: "Real Estate and Construction Management",
  },
  { label: "Others please specify", value: "Others" },
];
export const typeOfFirmOptions = [
  {
    label: "Construction Advisory firm",
    value: "Construction Advisory firm",
  },
  { label: "Design-Build firm", value: "Design-Build firm" },
  { label: "Design firms", value: "Design firms" },
  {
    label: "Engineering Consulting",
    value: "Engineering Consulting",
  },
  {
    label: "Federal/Heavy Construction firm",
    value: "Federal/Heavy Construction firm",
  },
  { label: "General Contractor", value: "General Contractor" },
  {
    label: "Government Agency/Department",
    value: "Government Agency/Department",
  },
  {
    label: "Mechanical, Electrical and Plumbing (MEP) firm",
    value: "Mechanical, Electrical and Plumbing (MEP) firm",
  },
  {
    label: "Multifamily/Mixed Use Construction",
    value: "Multifamily/Mixed Use Construction",
  },
  {
    label: "Non-Residential Building Construction firm",
    value: "Non-Residential Building Construction firm",
  },
  {
    label: "Residential Building Construction firm",
    value: "Residential Building Construction firm",
  },
  {
    label: "Special Trade Contracting firm",
    value: "Special Trade Contracting firm",
  },
  {
    label: "Transportation and Highway Construction firm",
    value: "Transportation and Highway Construction firm",
  },
  {
    label: "University Department/Division",
    value: "University Department/Division",
  },
  { label: "Others please specify", value: "Others" },
];
export const sizeOfFirmOptions = [
  { label: "Less than 10 employees", value: "Less than 10 employees" },
  { label: "10+ employees", value: "10+ employees" },
  { label: "20+ employees", value: "20+ employees" },
  { label: "50+ employees", value: "50+ employees" },
  { label: "100+ employees", value: "100+ employees" },
  { label: "250+ employees", value: "250+ employees" },
  { label: "500+ employees", value: "500+ employees" },
  { label: "1,000+ employees", value: "1,000+ employees" },
];

export const companySizeOptions = [
  { label: "Less than 10 employees", value: "Less than 10 employees" },
  { label: "10 - 19 employees", value: "10 - 19 employees" },
  { label: "20 - 49 employees", value: "20 - 49 employees" },
  { label: "50 - 99 employees", value: "50 - 99 employees" },
  { label: "100 - 249 employees", value: "100 - 249 employees" },
  { label: "250 - 499 employees", value: "250 - 499 employees" },
  { label: "500 - 999 employees", value: "500 - 999 employees" },
  { label: "1,000+ employees", value: "1,000+ employees" },
];

export const positionOptions = [
  { label: "Architect", value: "Architect" },
  { label: "Assistant Project Engineer", value: "Assistant Project Engineer" },
  { label: "Assistant Project Manager", value: "Assistant Project Manager" },
  {
    label: "Assistant Site Superintendent",
    value: "Assistant Site Superintendent",
  },
  { label: "Civil Engineer", value: "Civil Engineer" },
  {
    label: "Construction Data Analyst",
    value: "Construction Data Analyst",
  },
  { label: "Construction Manager", value: "Construction Manager" },
  { label: "Electrical Engineer", value: "Electrical Engineer" },
  { label: "Estimator", value: "Estimator" },
  { label: "Field/Project Engineer", value: "Field/Project Engineer" },
  {
    label: "Health and Safety Specialist",
    value: "Health and Safety Specialist",
  },
  { label: "Inspector", value: "Inspector" },
  { label: "Landscape Architect", value: "Landscape Architect" },
  {
    label: "Mechanical, Electrical and Plumbing (MEP) Specialist",
    value: "Mechanical, Electrical and Plumbing (MEP) Specialist",
  },
  { label: "Mechanical Engineer", value: "Mechanical Engineer" },
  { label: "Owner's Representative", value: "Owner's Representative" },
  { label: "President/CEO", value: "President/CEO" },
  { label: "Project Manager", value: "Project Manager" },
  { label: "Sales Associate", value: "Sales Associate" },
  { label: "Sales Engineer", value: "Sales Engineer" },
  { label: "Senior Manager", value: "Senior Manager" },
  { label: "Site Manager", value: "Site Manager" },
  { label: "Structural Engineer", value: "Structural Engineer" },
  { label: "Superintendent", value: "Superintendent" },
  { label: "Surveyor", value: "Surveyor" },
  {
    label: "Talent Engagement Manager",
    value: "Talent Engagement Manager",
  },
  { label: "Tradesman", value: "Tradesman" },
  { label: "Vice President", value: "Vice President" },
  { label: "Others please specify", value: "Others" },
];
export const insTypeOptions = [
  {
    label: "Predominantly White Institution",
    value: "Predominantly White Institution",
  },
  {
    label: "Historically Black Institution",
    value: "Historically Black Institution",
  },
  {
    label: "Hispanic Serving Institution",
    value: "Hispanic Serving Institution",
  },
  { label: "Others, please specify", value: "Others" },
];
export const instructorTitleOptions = [
  { label: "Professor", value: "Professor" },
  { label: "Adjunct Professor", value: "Adjunct Professor" },
  { label: "Associate Professor", value: "Associate Professor" },
  { label: "Assistant Professor", value: "Assistant Professor" },
  { label: "Professor of Practice", value: "Professor of Practice" },
  {
    label: "Assistant Professor of Practice",
    value: "Assistant Professor of Practice",
  },
  {
    label: "Senior Professor of Practice",
    value: "Senior Professor of Practice",
  },
  { label: "Lecturer", value: "Lecturer" },
  { label: "Instructor", value: "Instructor" },
  { label: "Others, please specify", value: "Others" },
];
export const practitionerTitleOptions = [
  { label: "Mr.", value: "Mr" },
  { label: "Ms.", value: "Ms" },
  { label: "Mrs.", value: "Mrs" },
  { label: "Miss", value: "Miss" },
  { label: "Dr.", value: "Dr." },
  { label: "Others, please specify", value: "Others" },
];

export const typeTechnologyOptions = [
  "3D Printing",
  "Augmented Reality",
  "BIM",
  "Blockchain",
  "Digital Twin",
  "Exoskeleton (Robotics)",
  "Global Positioning System",
  "Laser scanner",
  "RFID",
  "Smart Building Materials",
  "Unmanned Aerial Vehicle (Drone)",
  "Virtual Reality",
  "Wearable Technology (e.g., Smart PPE)",
];
export const subjectWillingToSpeakOnOptions = [
  { label: "Estimating", value: "Estimating" },
  { label: "Scheduling", value: "Scheduling" },
  { label: "Project Management", value: "Project Management" },
  { label: "Program Management", value: "Program Management" },
  { label: "HVAC", value: "HVAC" },
  { label: "Site Logistics", value: "Site Logistics" },
  { label: "Structural Analysis", value: "Structural Analysis" },
  { label: "Lean Construction", value: "Lean Construction" },
  {
    label: "Process Planning and Design",
    value: "Process Planning and Design",
  },
  { label: "Business Management", value: "Business Management" },
  { label: "Waste Management", value: "Waste Management" },
  { label: "Foundations", value: "Foundations" },
];

export const diversityOptions = [
  { label: "Very High", value: "Very High" },
  { label: "High", value: "High" },
  { label: "Moderate", value: "Moderate" },
  { label: "Slightly", value: "Slightly" },
  { label: "Not diverse and inclusive", value: "Not diverse and inclusive" },
];

export const locationOptions = [
  { label: "Midwest", value: "Midwest" },
  { label: "Northeast", value: "Northeast" },
  { label: "Southeast", value: "Southeast" },
  { label: "Southwest", value: "Southwest" },
  { label: "West", value: "West" },
];

export const UserFields = [
  { key: "email", label: "Email", sortable: true },
  { key: "firstName", label: "First Name", sortable: true },
  { key: "lastName", label: "Last Name", sortable: true },
  { key: "title", label: "Title" },
  {
    key: "nameOrg",
    label: "Name of Organization",
    sortable: true,
  },
  "status",
  {
    key: "roleId",
    label: "Role",
  },
  { key: "actions", label: "", width: 80 },
];

export const FeedbackFields = [
  { key: "user.firstName", label: "First Name", sortable: true },
  { key: "user.lastName", label: "Last Name", sortable: true },
  { key: "rating", label: "rating" },
  { key: "message", label: "Message", width: "25%" },
  { key: "actions", label: "", width: 80 },
];

export const ContactUsFields = [
  { key: "firstName", label: "First Name", sortable: true },
  { key: "lastName", label: "Last Name", sortable: true },
  { key: "email", label: "Email" },
  { key: "nameOrg", label: "Name of Organization" },
  { key: "message", label: "Message", width: "25%" },
  { key: "actions", label: "", width: 80 },
];

export const FAQFields = [
  "userQuestion",
  "answer",
  "display",
  { key: "actions", label: "", width: 80 },
];

export const DashboardAccessRequestFields = [
  { key: "user.firstName", label: "First Name", sortable: true },
  { key: "user.lastName", label: "Last Name", sortable: true },
  { key: "user.email", label: "Email" },
  { key: "user.nameOrg", label: "Name of Organization" },
  { key: "actions", label: "", width: 80 },
];

export const DashboardFeedbackFields = [
  { key: "user.firstName", label: "First Name", sortable: true },
  { key: "user.lastName", label: "Last Name", sortable: true },
  { key: "message", label: "Message", width: "25%" },
  { key: "actions", label: "", width: 80 },
];

export const OrganizationFields = [
  { key: "name", label: "Name", sortable: true },
  { key: "originalName", label: "Name on User Profile", sortable: true },
  { key: "display", label: "Display on Screen" },
  { key: "actions", label: "", width: 80 },
];

export const ClassFields = [
  "courseCode",
  "courseTitle",
  "semester",
  "year",
  "academicLevel",
  "studentDept",
  "classSize",
  "status",
  { key: "actions", label: "", width: 80 },
];

export const InstructorFields = [
  { key: "user.email", label: "Email" },
  { key: "user.firstName", label: "First Name" },
  { key: "user.lastName", label: "Last Name" },
  "department",
  { key: "user.nameOrg", label: "Institution" },
  { key: "user.title", label: "Title" },
  { key: "user.gender", label: "Gender" },
  { key: "user.ethnicity", label: "Racial Identity" },
  "ageRange",
  { key: "actions", label: "", width: 80 },
];

export const IndustryPractitionerFields = [
  { key: "user.email", label: "Email" },
  { key: "user.firstName", label: "First Name" },
  { key: "user.lastName", label: "Last Name" },
  { key: "user.nameOrg", label: "Organization" },
  "position",
  "yearsOfExp",
  "expertise",
  "location",
  { key: "actions", label: "", width: 80 },
];

export const CourseRequestFields = [
  "courseRequestType",
  "studentDept",
  "academicLevel",
  "insDeliveryType",
  "classSize",
  "courseCode",
  "courseTitle",
  { key: "user.email", label: "Instructor Email" },
  "status",
  { key: "actions", label: "", width: 80 },
];

export const ResourceRequestFields = [
  "type",
  "description",
  { key: "user.firstName", label: "Instructor First Name" },
  { key: "user.lastName", label: "Instructor Last Name" },
  { key: "user.email", label: "Instructor Email" },
  "status",
  { key: "actions", label: "", width: 80 },
];

export const AvailabilityFields = [
  "courseRequestType",
  "insDeliveryType",
  "academicLevel",
  "studentDept",
  "insState",
  "insName",
  { key: "actions", label: "", width: 80 },
];

export const UploadFields = [
  "type",
  "description",
  { key: "user.email", label: "User Email" },
  { key: "user.firstName", label: "User First Name" },
  { key: "user.lastName", label: "User Last Name" },
  { key: "status", sortable: true },
  "reasonDelete",
  { key: "actions", label: "", width: 80 },
];

export const DashboardLabels = {
  "Predominantly White Institution": "PWI",
  "Historically Black Institution": "HBI",
  "Hispanic Serving Institution": "HSI",
  Labs: "Labs",
  Male: "M",
  Female: "F",
  "Non-binary": "NB",
  Others: "Others",
};
