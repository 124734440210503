import { defineStore } from "pinia";
import { CourseRequest, User } from "@/types";
import InstructorService from "@/services/instructor.service";
import { showErrorToast } from "@/utils";

export const useRecommendationsStore = defineStore("RecommendationsStore", {
  state: () => ({
    recommendations: [] as User[],
    selectedPractitioner: [] as User[],
    practitionerId: 0 as number,
    count: 0 as number,
  }),
  getters: {
    practitionerSelected(): boolean {
      return this.practitionerId > 0;
    },
  },
  actions: {
    setRecommendations(course: CourseRequest) {
      InstructorService.getRecommendations(course).then(
        (response) => {
          console.log(response);
          this.recommendations = response.data;
          if (this.selectedPractitioner.length > 0) this.disableSelected();
          this.count = response.data.length;
        },
        (error) => {
          const res =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          console.log(res);
        }
      );
    },
    selectPractitioner(userId: number) {
      if (this.selectedPractitioner.some((el) => el.id === userId)) {
        showErrorToast("Practitioner already selected.");
      } else if (this.selectedPractitioner.length >= 3) {
        showErrorToast(
          "Only a maximum of 3 selections is allowed. Please remove one of the current selections to add a new one."
        );
      } else {
        const index = this.recommendations.findIndex((el) => el.id === userId);
        this.selectedPractitioner.push(this.recommendations[index]);
        this.recommendations[index].disabled = true;
        this.recommendations[index].opened = false;
      }
    },
    removeSelectedPractitioner(userId: number) {
      const index = this.selectedPractitioner.findIndex(
        (el) => el.id === userId
      );
      const recommendationIndex = this.recommendations.findIndex(
        (el) => el.id === userId
      );
      this.selectedPractitioner.splice(index, 1);
      this.recommendations[recommendationIndex].disabled = false;
    },
    resetSelectedPractitioner() {
      this.selectedPractitioner.length = 0;
    },
    setPractitioner(userId: number) {
      this.practitionerId = userId;
    },
    moveToNext() {
      const index = this.recommendations.findIndex(
        (el) => el.id === this.practitionerId
      );
      this.practitionerId = this.recommendations[(index + 1) % this.count].id;
    },
    moveToPrev() {
      const index = this.recommendations.findIndex(
        (el) => el.id === this.practitionerId
      );
      this.practitionerId = this.recommendations[(index - 1) % this.count].id;
    },
    disableSelected() {
      this.recommendations.forEach((el) => {
        el.disabled = this.selectedPractitioner.some((e) => e.id === el.id);
      });
    },
  },
  persist: {
    storage: sessionStorage,
  },
});
