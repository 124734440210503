import { defineStore } from "pinia";
import { CourseRequest, User } from "@/types";

export const useCourseStore = defineStore("CourseStore", {
  state: () => ({
    course: {} as CourseRequest,
    practitionerId: 0 as number,
    courseAdded: false as boolean,
  }),
  getters: {
    status(): boolean {
      return Object.keys(this.course || {}).length > 0;
    },
    practitionerSelected(): boolean {
      return this.practitionerId > 0;
    },
  },
  actions: {
    addCourse(course) {
      this.course.user = {} as User;
      this.course = course;
    },
    removeCourse() {
      this.course = {} as CourseRequest;
    },
    setPractitioner(userId: number) {
      this.practitionerId = userId;
    },
    removePractitioner() {
      this.practitionerId = 0;
    },
    setCourseAdded(val: boolean) {
      this.courseAdded = val;
    },
  },
  persist: {
    storage: sessionStorage,
  },
});
