import { createApp } from "vue";
import App from "./App.vue";
import { createVuestic } from "vuestic-ui";
import "vuestic-ui/css";
import PrimeVue from "primevue/config";
import Tooltip from "primevue/tooltip";
import router from "./router";
import { createPinia } from "pinia";
import piniaPluginPersistedState from "pinia-plugin-persistedstate";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import vueCountryRegionSelect from "vue3-country-region-select";
import "vue-toast-notification/dist/theme-sugar.css";

import VueScrollTo from "vue-scrollto";

import { BootstrapVue3 } from "bootstrap-vue-3";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
import "primevue/resources/themes/lara-light-indigo/theme.css";
import "primevue/resources/primevue.min.css";
import "primeflex/primeflex.css";
import "@/assets/css/global.css"; // imports the global CSS before all other CSS files

const pinia = createPinia();
pinia.use(piniaPluginPersistedState);

import {
  faXmark,
  faRightFromBracket,
  faUpload,
  faCamera,
  faArrowUpFromBracket,
  faDownload,
  faCircleQuestion,
  faFile,
  faTrash,
  faPlus,
  faCheck,
  faCircleCheck,
  faCircleExclamation,
  faPencil,
  faChevronLeft,
  faMagnifyingGlass,
  faImage,
  faVideo,
  faPhotoFilm,
  faFlag,
  faLock,
  faLockOpen,
  faPenToSquare,
  faRotate,
  faChevronRight,
  faChevronDown,
  faChevronUp,
  faChartLine,
} from "@fortawesome/free-solid-svg-icons";
import {
  faLinkedin,
  faFacebook,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import {
  faCopyright,
  faBell,
  faFolderOpen,
  faFolder,
} from "@fortawesome/free-regular-svg-icons";

library.add(
  faLinkedin,
  faXTwitter,
  faFacebook,
  faCopyright,
  faXmark,
  faRightFromBracket,
  faUpload,
  faCamera,
  faArrowUpFromBracket,
  faBell,
  faDownload,
  faCircleQuestion,
  faFile,
  faTrash,
  faPlus,
  faCheck,
  faCircleCheck,
  faCircleExclamation,
  faPencil,
  faChevronLeft,
  faChevronRight,
  faFolderOpen,
  faFolder,
  faMagnifyingGlass,
  faImage,
  faVideo,
  faCircleQuestion,
  faPhotoFilm,
  faFlag,
  faLock,
  faLockOpen,
  faPenToSquare,
  faRotate,
  faChevronDown,
  faChevronUp,
  faChartLine
);

createApp(App)
  .use(createVuestic())
  .use(PrimeVue)
  .use(router)
  .use(pinia)
  .use(vueCountryRegionSelect)
  .use(BootstrapVue3)
  .use(VueScrollTo)
  .component("font-awesome-icon", FontAwesomeIcon)
  .directive("tooltip", Tooltip)
  .mount("#app");
