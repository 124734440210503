<script setup lang="ts"></script>

<style scoped></style>

<template>
  <div class="m-5 min-height header-space">
    <div class="text-align-center">
      <img
        src="@/assets/images/site/about.jpg"
        class="site-image mx-auto"
        alt="About Us"
      />
    </div>
    <div class="text-align-center padding-10">
      <p class="page-heading">About Us</p>
      <div class="heading-line">
        <img src="@/assets/images/site/line.svg" alt="" />
      </div>
      <br /><br />
      <h4>
        ConPEC is a web-based platform designed to integrate industry and
        academia for future workforce development collaborations.
      </h4>
      <br />
      <h6>
        This platform provides instructors with easy and equitable access to
        industry practitioners to provide necessary course-support such as guest
        lecture, lab session and field trips to complement pedagogical efforts
        in the classroom. These collaborations would ensure students’
        interaction with their communities of practice as well as facilitate the
        incorporation of industry’s input in preparing the future workforce.
        <br />
        <br />
        The collaboration would also help industry practitioners to get involved
        as partners and stakeholders in academia’s workforce development
        efforts. It is our goal that this would contribute to reducing skill
        gaps and mismatches as well as the separation between industry and
        academia. We would like to implore you to join us in this effort by
        using the platform and encouraging others (construction industry
        practitioners and instructors) to do so.
        <br /><br />
        Together we can confront the current challenges of workforce development
        and make students industry-ready. <br /><br />
        This project is a joint effort by Virginia Tech and North Carolina
        Agricultural and Technical State University funded by the National
        Science Foundation. <br /><br />
      </h6>
      <h2>
        <router-link :to="{ name: 'register' }"> Join us </router-link>
        today!
      </h2>
    </div>
  </div>
</template>
